import { Box, SxProps, Theme } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import { Icon } from 'shared/ui/Icon';

interface Props {
	iconId: string;
	active?: boolean;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	sxWrapper?: SxProps<Theme>;
	sxIcon?: CSSProperties;
}

export const IconButton: FunctionComponent<Props> = ({ sxWrapper, sxIcon, iconId, active, onClick }) => {
	return (
		<Box
			sx={{
				minWidth: '46px',
				maxWidth: '46px',
				height: '46px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: active ? ColorPalette.royalBlue : ColorPalette.white,
				color: active ? ColorPalette.white : ColorPalette.royalBlue,
				borderRadius: '12px',
				boxShadow: Shadow['2'],
				cursor: 'pointer',
				transition: 'all .3s',
				position: 'relative',
				...sxWrapper,
			}}
			onClick={onClick}
		>
			<Icon id={iconId} style={{ width: '24px', height: '24px', color: 'inherit', ...sxIcon }} />
		</Box>
	);
};
