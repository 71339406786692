import { FC } from 'react';
import { format } from 'date-fns';
import { Dialog, Typography, Box } from '@mui/material';
import { AlreadyExportedFilesType } from 'entities/Files/types';
import { useTranslation } from 'react-i18next';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';

interface Props {
	open: boolean;
	ids: AlreadyExportedFilesType[];
	handleClose: () => void;
	handleExport: () => void;
}

const AlreadyExportedFiles: FC<Props> = ({ open, ids, handleClose, handleExport }) => {
	const { t } = useTranslation();

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('shared_texts.attention') as string }}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: 3 }}>
				{ids.length === 1
					? t('files.modal.alrady_exported_description_1', {
							date: format(new Date(ids[0].createdAt), 'dd-MM-yyyy HH:mm'),
					  })
					: t('files.modal.alrady_exported_description_2')}
			</Typography>

			{ids.length !== 1 && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 1,
						marginBottom: 3,
						maxHeight: '200px',
						overflow: 'auto',
					}}
				>
					{ids.map((file, index) => (
						<Typography key={index} variant={'body1'} color={ColorPalette.astronaut}>
							{file.fileName} - {format(new Date(file.createdAt), 'dd-MM-yyyy HH:mm')}
						</Typography>
					))}
				</Box>
			)}
			<ActionsForDialog
				cancelLabel={t('shared_texts.cancel')}
				cancelAction={handleClose}
				applyLabel={t('shared_texts.continue')}
				applyAction={handleExport}
			/>
		</Dialog>
	);
};

export default AlreadyExportedFiles;
