import { FC } from 'react';
import { useField } from 'formik';
import { AutocompleteProps } from 'shared/ui/autocomplete/Autocomplete';
import Autocomplete from '../Autocomplete';

const FormAutocomplete: FC<AutocompleteProps> = ({ ...props }) => {
	const [field, meta, helpers] = useField({ name: props.name });

	return (
		<Autocomplete
			{...field}
			{...props}
			onChange={(elem) => helpers.setValue(elem)}
			errorText={meta.touched && meta.error ? (meta.error as string) : undefined}
		/>
	);
};

export default FormAutocomplete;
