import { Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FunctionComponent, useState } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Icon } from 'shared/ui/Icon';
import { IconButton } from 'shared/ui/IconButton';
import { Option } from 'shared/ui/OptionsDropdown/types';

interface Props {
	iconId: string;
	optionList: Array<Option>;
	selectedOption?: Option;
	onOptionSelect?: (option: Option) => void;
	forTableHeader?: boolean;
}

export const OptionsDropdown: FunctionComponent<Props> = ({
	iconId,
	optionList,
	selectedOption,
	onOptionSelect,
	forTableHeader,
}) => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				iconId={iconId}
				onClick={handleClick}
				sxWrapper={
					forTableHeader
						? {
								minWidth: 'inherit',
								width: '20px',
								height: '20px',

								borderRadius: '2px',
								transform: 'rotate(90deg)',
						  }
						: {}
				}
				sxIcon={
					forTableHeader
						? {
								width: '14px',
								height: '14px',
								maxWidth: '14px',
						  }
						: undefined
				}
			/>
			<Menu
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				autoFocus={false}
				anchorOrigin={{ horizontal: forTableHeader ? 'left' : 'right', vertical: 'bottom' }}
				transformOrigin={{ horizontal: forTableHeader ? 'left' : 'right', vertical: 'top' }}
				sx={{
					'marginTop': '16px',
					'& .MuiBackdrop-root': {
						background: 'transparent',
					},
					'& .MuiList-root': {
						padding: 0,
						overflow: 'hidden',
					},
					'& .MuiPaper-root': {
						backgroundColor: ColorPalette.charade,
						borderRadius: '10px',
					},
				}}
			>
				{optionList.map((option) => (
					<MenuItem
						key={option.value}
						sx={{
							'position': 'relative',
							'padding': '6px 32px 6px 8px',
							'backgroundColor':
								selectedOption === option ? ColorPalette.royalBlue : 'transparent',
							'&:hover': {
								backgroundColor: ColorPalette.royalBlue,
							},
							'transition': 'backgroundColor .3s',
						}}
						onClick={(e) => {
							e.stopPropagation();
							onOptionSelect && onOptionSelect(option);
							option?.action && option.action();
							handleClose();
						}}
					>
						<Typography variant={'caption'} color={ColorPalette.white}>
							{t(option.label)}
						</Typography>
						<Icon
							id={'done_round'}
							style={{
								display: selectedOption === option ? 'block' : 'none',
								width: '16px',
								height: '12px',
								position: 'absolute',
								top: '50%',
								right: '8px',
								transform: 'translateY(-50%)',
							}}
							color={ColorPalette.white}
						/>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};
