import { axiosInstance } from 'shared/config/axios';
import {
	GetPartnersRequest,
	CreatePartnerRequest,
	CreatePartnerViaFileRequest,
	UpdatePartnerRequest,
	RemovePartnersRequest,
	GetReplaceSettingsRequest,
	UpdateReplaceSettingsRequest,
	GetAssertionsSettingsRequest,
	UpdateAssertionsSettingsRequest,
	GetCategoriesSettingsRequest,
	UpdateCategoriesSettingsRequest,
	UpdatePartnersAutoConnectRequest,
} from 'entities/Partners/api/types';

export const getPartners: GetPartnersRequest = (queries) => {
	return axiosInstance.get('/partners', { params: { ...queries } });
};

export const postPartner: CreatePartnerRequest = ({ data }) => {
	return axiosInstance.post('/partners', data);
};

export const postPartnerViaFile: CreatePartnerViaFileRequest = (data) => {
	return axiosInstance.post('/partners/via-file', data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

export const deletePartnes: RemovePartnersRequest = (data) => {
	return axiosInstance.delete('/partners', { data });
};

export const patchPartner: UpdatePartnerRequest = ({ partnerId, data }) => {
	return axiosInstance.put(`/partners/${partnerId}`, data);
};

export const getReplaceSettings: GetReplaceSettingsRequest = (partnerId) => {
	return axiosInstance.get(`/partners/${partnerId}/replace-settings`);
};

export const updateReplaceSettings: UpdateReplaceSettingsRequest = ({ partnerId, data }) => {
	return axiosInstance.put(`/partners/${partnerId}/replace-settings`, data);
};

export const getAssertionsSettings: GetAssertionsSettingsRequest = (partnerId) => {
	return axiosInstance.get(`/partners/${partnerId}/assertions-settings`);
};

export const updateAssertionsSettings: UpdateAssertionsSettingsRequest = ({ partnerId, data }) => {
	return axiosInstance.put(`/partners/${partnerId}/assertions-settings`, data);
};

export const getCategoriesSettings: GetCategoriesSettingsRequest = (partnerId) => {
	return axiosInstance.get(`/partners/${partnerId}/categories-settings`);
};

export const updateCategoriesSettings: UpdateCategoriesSettingsRequest = ({ partnerId, data }) => {
	return axiosInstance.put(`/partners/${partnerId}/categories-settings`, data);
};

export const patchPartnerAutoConnect: UpdatePartnersAutoConnectRequest = (data) => {
	return axiosInstance.patch('partners/auto-connect', data);
};
