import { FC } from 'react';
import { SwitchProps } from '@mui/material';
import { useField } from 'formik';
import { Switch } from 'shared/ui/Switch';

interface Props extends SwitchProps {
	name: string;
}

export const FormSwitch: FC<Props> = ({ name, ...props }) => {
	const [field] = useField({ name });

	return <Switch checked={field.value} {...field} {...props} />;
};
