import { useEffect } from 'react';
import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/ui/Button';
import { CloseModalButton } from 'shared/ui/CloseModalButton';
import { Paper } from 'shared/ui/Paper';
import { ColorPalette } from 'shared/consts/colorPalette';
import { useSubscriptionStoreStore } from 'widgets/Subscriptions/model/state';
import { STypesEnum } from 'entities/Subscriptions/enums';
import { downloadFile } from 'shared/helpers/downloadFile';
import { useAuthStore } from 'processes/auth/model/state';
import { DefaultExportType, DefaultExportDesination } from 'entities/Company/types';

const DownloadModal = ({
	open,
	handleClose,
	fileId,
	handleFtpOpen,
	fileType,
	setFileType,
}: {
	open: boolean;
	handleClose: () => void;
	fileId: string[];
	handleFtpOpen: (fileType?: DefaultExportType) => void;
	fileType?: DefaultExportType;
	setFileType: (type: DefaultExportType | undefined) => void;
}) => {
	const { t } = useTranslation();
	const { currentSubscription } = useSubscriptionStoreStore();
	const { company } = useAuthStore();

	useEffect(() => {
		if (open && company?.defaultExport?.defaultType) {
			setFileType(company?.defaultExport?.defaultType);
		} else {
			setFileType(undefined);
		}
	}, [open, company]);

	const handleDownload = () => {
		//type must be one of the following values: XML, CSV, JSON,  XLSX
		const type = company?.defaultExport?.defaultType || fileType;
		downloadFile({ fileId, type: type as string });
	};

	const onOpenFtpImportFileModalHandler = () => {
		handleFtpOpen();
	};

	const selectTypeHandler = (type: DefaultExportType) => {
		setFileType(type);
		if (company?.defaultExport?.defaultDestination === DefaultExportDesination.FTP) {
			handleClose();
			handleFtpOpen(type);
		}
		if (company?.defaultExport?.defaultDestination === DefaultExportDesination.DIRECT) {
			handleClose();
			downloadFile({ fileId, type: type as string });
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('shared_texts.download_modal.title') as string }}
			sx={{
				'& .MuiPaper-root': {
					width: '536px',
					maxWidth: '536px',
					overflow: 'inherit',
				},
			}}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ margin: '24px 0 34px 0' }}>
				{t('shared_texts.download_modal.description')}
			</Typography>
			<CloseModalButton action={handleClose} />

			{!fileType && !company?.defaultExport?.defaultType && (
				<>
					<Button
						onClick={() => {
							selectTypeHandler(DefaultExportType.XLSX);
						}}
						variant={'contained'}
						fullWidth
					>
						{t('shared_texts.download_modal.download_xls')}
					</Button>

					<Button
						sx={{ marginTop: '24px' }}
						onClick={() => {
							selectTypeHandler(DefaultExportType.JSON);
						}}
						variant={'contained'}
						fullWidth
					>
						{t('shared_texts.download_modal.download_json')}
					</Button>

					<Button
						sx={{ marginTop: '24px' }}
						onClick={() => {
							selectTypeHandler(DefaultExportType.CSV);
						}}
						variant={'contained'}
						fullWidth
					>
						{t('shared_texts.download_modal.download_csv')}
					</Button>

					<Button
						sx={{ marginTop: '24px' }}
						onClick={() => {
							selectTypeHandler(DefaultExportType.XML);
						}}
						variant={'contained'}
						fullWidth
					>
						{t('shared_texts.download_modal.download_xml')}
					</Button>
				</>
			)}
			{fileType && !company?.defaultExport?.defaultDestination && (
				<>
					<Button
						onClick={() => {
							handleDownload();
							handleClose();
						}}
						variant={'contained'}
						fullWidth
					>
						{t('shared_texts.download_modal.download_on_computer')}
					</Button>

					{(currentSubscription?.subscriptionType === STypesEnum.PRO ||
						currentSubscription?.subscriptionType === STypesEnum.PAY_AS_YOU_GO ||
						currentSubscription?.subscriptionType === STypesEnum.UNLIMITED) && (
						<Button
							sx={{ marginTop: '24px' }}
							onClick={onOpenFtpImportFileModalHandler}
							variant={'contained'}
							fullWidth
						>
							{t('shared_texts.download_modal.download_on_ftp')}
						</Button>
					)}
				</>
			)}
		</Dialog>
	);
};

export default DownloadModal;
