import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import { Box, Typography } from '@mui/material';
import { getCoUsersOfUser, getAdminCoUserDealers } from 'entities/admin/Users/api';
import { UserForAdmin } from 'entities/admin/Users/types';
import { ColorPalette } from 'shared/consts/colorPalette';
import CoUserFilters from 'widgets/CoUsers/ui/CoUserFilters';
import Chip from 'shared/ui/Chip';
import { useCoUsersStore } from 'store/co-users';
import { CoUsersTable } from './CoUsersTable';

interface Props {
	user: UserForAdmin | null;
}

const CoUsers: FC<Props> = ({ user }) => {
	const { t } = useTranslation();
	const { coUsersFilters, setDealersList, setCoUsersFilters } = useCoUsersStore();

	const { data: coUsersData } = useQuery({
		queryKey: ['admin/co-users', coUsersFilters],
		queryFn: () =>
			getCoUsersOfUser({
				userId: user?._id || '',
				...coUsersFilters,
			}),
	});

	useQuery({
		queryKey: ['co-users/dealers'],
		queryFn: () => getAdminCoUserDealers({ userId: user?._id || '' }),
		onSuccess: (response) => {
			setDealersList(response?.data);
		},
	});

	const selectedTableFilters = useMemo(() => {
		if (coUsersFilters) {
			const { from, to, ...rest } = coUsersFilters;
			return {
				date:
					from && to
						? `${format(new Date(from), 'dd/MM/yyy')} - ${format(new Date(to), 'dd/MM/yyy')}`
						: undefined,
				...rest,
			};
		}
		return [];
	}, [coUsersFilters]);

	return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ margin: '44px 0 38px 0' }}>
					{t('co_users.title')}
				</Typography>
				<CoUserFilters />
			</Box>
			<Box sx={{ display: 'flex', gap: 1, marginBottom: 1 }}>
				{Object.entries(selectedTableFilters).map(
					([key, value]) =>
						value && (
							<Chip
								key={key}
								label={value as string}
								onDelete={() => {
									if (key === 'date') {
										setCoUsersFilters({
											...coUsersFilters,
											from: undefined,
											to: undefined,
										});
									} else {
										setCoUsersFilters({ ...coUsersFilters, [key]: undefined });
									}
								}}
								color="secondary"
								size="small"
							/>
						),
				)}
			</Box>
			<CoUsersTable coUsers={coUsersData?.data || []} />
		</>
	);
};

export default CoUsers;
