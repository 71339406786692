import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getFiles, exportFileFtp, patchAssertionSetToFile, checkExportedFiles } from 'entities/Files/api';
import { GetFilesReq } from 'entities/Files/api/types';
import { getAssertionsSets } from 'entities/Assertions/api';
import { PageHeader } from 'shared/ui/PageHeader';
import { FileType, AlreadyExportedFilesType } from 'entities/Files/types';
import { SearchParams, useFilesSearchParams } from 'shared/helpers/useFilesSearchParams';
import { DeleteFilesModal } from 'widgets/files/DeleteFilesModal';
import { FilesDashboard } from 'widgets/files/FilesDashboard';
import { FiltersAndSearch } from 'widgets/files/FiltersAndSearch';
import { AsModalList } from 'widgets/Extensions/Details/ui/AssertionSets/ui/AsModalList';
import DownloadModal from 'pages/Files/partials/download-modal';
import RequiresReviewModal from 'pages/Files/partials/requires-review-modal';
import AlreadyExportedFiles from 'pages/Files/partials/already-exported-files';
import { useFilesStore } from 'widgets/editFile/model/state';
import { useCustomModelStore } from 'widgets/customModel/state';
import { ReadableCategories } from 'widgets/files/Header/consts/ReadableCategories';
import { FtpImportFilesModal } from 'widgets/importFiles/importFilesFromFtp/ui/FtpImportFilesModal';
import { useAssertionSetsStore } from 'widgets/Extensions/Details/ui/AssertionSets/model/state';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { Prefixes } from 'app/config/routes/consts';
import { useAuthStore } from 'processes/auth/model/state';
import { downloadFile } from 'shared/helpers/downloadFile';
import { DefaultExportDesination, DefaultExportType } from 'entities/Company/types';

export const Files: FC = () => {
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { category, partnerId } = useParams();
	const { company } = useAuthStore();
	const { files, updateFilesData, setFilePartnerId, setFileDetails } = useFilesStore();
	const { setCustomFileDetails } = useCustomModelStore();
	const { assertionsSets, setAssertionsSetsData } = useAssertionSetsStore();
	const { searchParams, changeSearchParams } = useFilesSearchParams();
	const [openFtpModal, setOpenFtpModal] = useState(false);
	const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);
	const [requiresReviewModalOpen, setRequiresReviewModalOpen] = useState(false);
	const [reviewParams, setReviewParams] = useState({ qty: false, partners: false });
	const [alreadyExportedFilesModalOpen, setAlreadyExportedFilesModalOpen] = useState(false);
	const [fileType, setFileType] = useState<DefaultExportType>();
	const [fileIdForAction, setFileIdForAction] = useState('');
	const [selectedFiles, setSelectedFiles] = useState<Array<string>>([]);
	const [deleteFilesOpen, setDeleteFilesOpen] = useState(false);
	const [filesCounters, setFilesCounters] = useState<any>(0);
	const [isAssertionsOpen, setIsAssertionsOpen] = useState(false);
	const [triggerExport, setTriggerExport] = useState(false);
	const [alreadyExportedFiles, setAlreadyExportedFiles] = useState<AlreadyExportedFilesType[]>();

	const defaultAsertionId = useMemo(() => {
		if (fileIdForAction) {
			const file = files?.find((item) => item._id === fileIdForAction);
			return assertionsSets?.find((item) => item._id === file?.assertionSetsId)?._id;
		}
	}, [selectedFiles]);

	const fileIdsforActions = useMemo(
		() => (fileIdForAction ? [fileIdForAction] : selectedFiles),
		[fileIdForAction, selectedFiles],
	);

	const searchParamsForQuery: GetFilesReq = useMemo(() => {
		const { uploadSource, coUserId, importFrom, importTo, autoRemoveFrom, autoRemoveTo, ...rest } =
			searchParams;
		const preparedSearchParamsForQuery: GetFilesReq = { ...rest };
		if (uploadSource !== 'all') preparedSearchParamsForQuery['uploadSource'] = uploadSource;
		if (coUserId !== 'nobody') preparedSearchParamsForQuery['coUserId'] = coUserId;
		if (importFrom) preparedSearchParamsForQuery['importFrom'] = importFrom;
		if (importTo) preparedSearchParamsForQuery['importTo'] = importTo;
		if (autoRemoveFrom) preparedSearchParamsForQuery['autoRemoveFrom'] = autoRemoveFrom;
		if (autoRemoveTo) preparedSearchParamsForQuery['autoRemoveTo'] = autoRemoveTo;

		return preparedSearchParamsForQuery;
	}, [searchParams]);

	const preparedFilesData = useMemo(() => {
		const preparedFiles: Record<string, Array<FileType>> = {};
		files?.forEach((file) => {
			const date = format(new Date(file.createdAt), 'dd/MM/yyyy');
			preparedFiles[date] = [...(preparedFiles[date] ?? []), { ...file }];
		});
		return preparedFiles;
	}, [files]);

	const { mutate: ftpExportMutate, isLoading: ftpExportIsLoading } = useMutation({
		mutationKey: ['post/files/export/ftp-export'],
		mutationFn: exportFileFtp,
	});

	const { mutate: checkExportMutate } = useMutation({
		mutationKey: ['post/files/export/check-export'],
		mutationFn: checkExportedFiles,
	});

	const { mutate: fileAssertionMutate, isLoading: fileAssertionLoading } = useMutation({
		mutationKey: ['patch/files/assertion-sets'],
		mutationFn: patchAssertionSetToFile,
	});
	const query = useQuery({
		queryKey: ['get/files', searchParamsForQuery],
		queryFn: () => getFiles(searchParamsForQuery || ''),
		onSuccess: (response) => {
			setFilesCounters(response?.data?.counters);
			updateFilesData({ files: response?.data?.results });

			if (response.data.counters.inProcess > 0) {
				setTimeout(() => {
					query.refetch();
				}, 6000);
			}
		},
		onError: (err) => {
			updateFilesData({ files: null });
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		},
	});

	useQuery({
		queryKey: ['get/assertion-sets'],
		queryFn: () => getAssertionsSets(),
		onSuccess: (response) => {
			setAssertionsSetsData(response?.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		},
	});

	useEffect(() => {
		setFileDetails(null);
		setCustomFileDetails(null);
	}, []);

	useEffect(() => {
		if (category) {
			handleToggleAllFiles(true);
			changeSearchParams({
				category: (category !== 'all' ? [category] : []) as SearchParams['category'],
			});
		}
	}, [category]);

	useEffect(() => {
		if (partnerId) {
			changeSearchParams({ partnerId });
		}
	}, [partnerId]);

	useEffect(() => {
		queryClient.fetchQuery({ queryKey: ['get/files'] });
	}, [searchParamsForQuery]);

	useEffect(() => {
		if (triggerExport) {
			handleFTPImport('/');
			setTriggerExport(false);
		}
	}, [triggerExport]);

	const handleToggleFile = (fileId: string) => {
		setSelectedFiles((prevState) => {
			return prevState.includes(fileId)
				? prevState.filter((i) => i !== fileId)
				: [...prevState, fileId];
		});
	};
	const handleToggleAllFiles = useCallback(
		(refresh?: boolean) => {
			setSelectedFiles((prevState) => {
				if (!files || refresh || (files && files?.length === prevState.length)) {
					return [];
				}
				if (files && files?.length > prevState.length) {
					return [...files.map((file) => file._id)];
				}
				return [];
			});
		},
		[files],
	);

	const handleEditFile = (fileId: string) => {
		if (partnerId) {
			setFilePartnerId(partnerId);
		}
		navigate(`/${Prefixes.editFile}${fileId}`);
	};

	const handleFTPImport = useCallback(
		(localPath: string, type?: DefaultExportType) => {
			const payloadFileType = type || fileType || company?.defaultExport.defaultType;

			if (payloadFileType) {
				const mutationValues = {
					type: payloadFileType,
					ftpPath: localPath,
					id: fileIdsforActions,
					isCompanyFtp: true,
				};

				ftpExportMutate(mutationValues, {
					onSuccess: async () => {
						setOpenFtpModal(false);
						handleResetFileIds();
						setDownloadModalIsOpen(false);
						enqueueSnackbar({
							message: t('files.snackbar.success_esport'),
							variant: 'success',
						});
					},
					onError: (err) => {
						const defaultErrorMessage = t('files.snackbar.error_while_uploading');
						const errorMessage = getErrorMessage(err, defaultErrorMessage);
						enqueueSnackbar({
							message: errorMessage,
							variant: 'error',
						});
					},
				});
			}
		},
		[fileType, fileIdsforActions],
	);

	const handleExportFile = (fileId?: string) => {
		const qtyReview = !!(fileId
			? files?.find((file) => file._id === fileId)?.requiresReview
			: !!selectedFiles.filter((id) => files?.find((file) => file._id === id)?.requiresReview).length);

		const partnersReview = !!(
			!files?.find((file) => file._id === fileId)?.partnerId && company?.autoConnectPartners
		);

		if (fileId) {
			setFileIdForAction(fileId);
		}
		if (qtyReview || partnersReview) {
			setRequiresReviewModalOpen(true);
			setReviewParams({ qty: qtyReview, partners: partnersReview });
		} else {
			checkIfExportedHandler(fileId ? [fileId] : selectedFiles);
		}
	};

	const checkIfExportedHandler = (fileIds: string[]) => {
		checkExportMutate(
			{ ids: fileIds },
			{
				onSuccess: (response) => {
					if (response.data.length) {
						setAlreadyExportedFiles(response.data);
						setAlreadyExportedFilesModalOpen(true);
					} else {
						finalExportHandler();
					}
				},
			},
		);
	};

	const finalExportHandler = () => {
		setAlreadyExportedFilesModalOpen(false);
		setAlreadyExportedFiles(undefined);
		if (company?.defaultExport) {
			if (!company?.defaultExport?.defaultType || !company?.defaultExport?.defaultDestination) {
				setDownloadModalIsOpen(true);
			} else if (company?.defaultExport?.defaultDestination === DefaultExportDesination.FTP) {
				setTriggerExport(true);
			} else {
				downloadFile({
					fileId: fileIdsforActions,
					type: company?.defaultExport?.defaultType,
				});
			}
		} else {
			setDownloadModalIsOpen(true);
		}
	};

	const handleDeleteFile = useCallback(
		(fileId?: string) => {
			if (!fileId && selectedFiles.length === 0) {
				return enqueueSnackbar({
					message: t('files.snackbar.no_files_to_remove'),
					variant: 'error',
				});
			}
			if (fileId) {
				setFileIdForAction(fileId);
			}
			setDeleteFilesOpen(true);
		},
		[selectedFiles],
	);

	const handleAssertions = (fileId?: string) => {
		if (fileId) {
			setFileIdForAction(fileId);
		}
		setIsAssertionsOpen(true);
	};

	const updateFileAssertionsHandler = (asId?: string) => {
		fileAssertionMutate(
			{
				ids: fileIdsforActions,
				assertionSetsId: !asId ? null : asId,
			},
			{
				onSuccess: async () => {
					setIsAssertionsOpen(false);
					if (asId) {
						enqueueSnackbar({
							message: t('assertions.modal.success_adding'),
							variant: 'success',
						});
					} else {
						enqueueSnackbar({
							message: t('assertions.modal.success_removing'),
							variant: 'success',
						});
					}
					handleResetFileIds();
					await queryClient.refetchQueries({ queryKey: ['get/files'] });
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	const handleResetFileIds = () => {
		setSelectedFiles([]);
		setFileIdForAction('');
	};

	return (
		<>
			<AsModalList
				open={isAssertionsOpen}
				handleApply={updateFileAssertionsHandler}
				handleRemoveAssertions={updateFileAssertionsHandler}
				handleClose={() => {
					setIsAssertionsOpen(false);
					handleResetFileIds();
				}}
				loading={fileAssertionLoading}
				defaultAssertionId={defaultAsertionId}
			/>
			<DeleteFilesModal
				ids={fileIdsforActions}
				open={deleteFilesOpen}
				handleClose={() => {
					handleResetFileIds();
					setDeleteFilesOpen(false);
				}}
			/>
			{!partnerId && (
				<PageHeader
					title={`${t('files.title')} ${t(ReadableCategories[String(category)])}`}
					description={t(`files.description.${searchParams.status}`)}
				/>
			)}
			<FiltersAndSearch
				category={category}
				filesCounters={filesCounters}
				searchParams={searchParams}
				changeSearchParams={changeSearchParams}
				handleToggleAllFiles={handleToggleAllFiles}
			/>
			<FilesDashboard
				files={preparedFilesData}
				filesCount={files?.length ?? 0}
				selectedFiles={selectedFiles}
				handleToggleFile={handleToggleFile}
				handleToggleAllFiles={handleToggleAllFiles}
				handleEditFile={handleEditFile}
				handleExportFile={handleExportFile}
				handleDeleteFile={handleDeleteFile}
				handleAssertions={handleAssertions}
			/>
			<DownloadModal
				open={downloadModalIsOpen}
				handleClose={() => {
					handleResetFileIds();
					setDownloadModalIsOpen(false);
				}}
				fileId={fileIdsforActions}
				handleFtpOpen={(type) => {
					if (type) {
						handleFTPImport('/', type);
					} else {
						setOpenFtpModal(true);
					}
				}}
				fileType={fileType}
				setFileType={setFileType}
			/>
			<FtpImportFilesModal
				open={openFtpModal}
				handleImport={(localPath) => handleFTPImport(localPath)}
				handleClose={() => setOpenFtpModal(false)}
				exportFtp
				isPending={ftpExportIsLoading}
			/>
			<RequiresReviewModal
				params={reviewParams}
				open={requiresReviewModalOpen}
				handleClose={() => {
					setRequiresReviewModalOpen(false);
					handleResetFileIds();
				}}
				handleExport={() => {
					setRequiresReviewModalOpen(false);
					checkIfExportedHandler(fileIdsforActions);
				}}
			/>
			<AlreadyExportedFiles
				open={alreadyExportedFilesModalOpen}
				ids={alreadyExportedFiles || []}
				handleClose={() => {
					setAlreadyExportedFilesModalOpen(false);
					setAlreadyExportedFiles(undefined);
					handleResetFileIds();
				}}
				handleExport={finalExportHandler}
			/>
		</>
	);
};
