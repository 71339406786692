import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Routes } from 'app/config/routes/consts';
import { GetPartnersRes } from 'entities/Partners/api/types';
import { EmptyTable } from 'shared/ui/EmptyTable';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';
import { PartnersTableColumns, PartnersColumnsType } from 'widgets/Partners/ui/PartnerTable/Columns';

type PartnersPropsType = {
	data: GetPartnersRes | undefined;
	selectedPartnerIds: string[];
	editHandler: (partnerId: string) => void;
	deleteHandler: (partnerId?: string) => void;
	togglePartner: (partnerId: string) => void;
	toggleAllPartners: () => void;
	onPageChange: (page: number) => void;
};

export const PartnersTable: FC<PartnersPropsType> = ({
	data,
	selectedPartnerIds,
	editHandler,
	deleteHandler,
	togglePartner,
	toggleAllPartners,
	onPageChange,
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const preparedPartners: PartnersColumnsType[] = useMemo(() => {
		if (!data?.results.length) return [];
		return data.results.map((item) => ({
			...item,
			id: item._id,
			partnersCount: data.totalCount,
			selectedPartnerIds,
			editHandler,
			deleteHandler,
			togglePartner,
			toggleAllPartners,
			documentHandler: () => navigate(`${Routes.partners}/${item._id}/documents`),
			settingsHandler: () => navigate(`${Routes.partners}/${item._id}/settings`),
			onRowChange: () => navigate(`${Routes.partners}/${item._id}/documents`),
		}));
	}, [data, selectedPartnerIds]);

	return (
		<>
			{preparedPartners.length ? (
				<TableWithHeader
					columns={PartnersTableColumns}
					rows={preparedPartners}
					pagination={{
						page: data?.page || 0,
						totalCount: data?.totalCount || 0,
						perPage: data?.perPage || 0,
						onPageChange,
					}}
				/>
			) : (
				<EmptyTable text={t('shared_texts.not_found')} />
			)}
		</>
	);
};
