import { getFileExport } from 'entities/Files/api';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { enqueueSnackbar } from 'notistack';

export const downloadFile = async ({ fileId, type }: { fileId: string[]; type: string }) => {
	for (let i = 0; i < fileId.length; i++) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		await getFileExport({ fileId: fileId[i], type })
			.then((response: any) => {
				const disposition = response.headers.get('content-disposition');
				let filename = 'filename.json';
				if (disposition && disposition.indexOf('attachment') !== -1) {
					const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					const matches = filenameRegex.exec(disposition);
					if (matches != null && matches[1]) {
						filename = matches[1].replace(/['"]/g, '');
					}
				}
				const url = window.URL.createObjectURL(response.data);
				const a = document.createElement('a');
				a.href = url;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				a.remove();
			})
			.catch((error) => {
				const errorMessage = getErrorMessage(error);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			});
	}
};
