import * as yup from 'yup';

export const WarehouseValidationSchema = yup.object({
	code: yup.string().required('extensions.validation.article_number'),
	description: yup.string().required('extensions.validation.article_description'),
	partnerId: yup
		.object({
			value: yup.string().notRequired(),
			label: yup.string().notRequired(),
		})
		.nullable(),
});
