import { FC } from 'react';
import { styled, Chip as MuiChip, ChipProps } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';

type Props = ChipProps;

const Chip: FC<Props> = ({ ...props }) => {
	return <ChipStyled {...props} />;
};

export default Chip;

const ChipStyled = styled(MuiChip)`
	background: ${ColorPalette.portage};
	color: ${ColorPalette.white};
	padding: 10px;
	height: 40px;
	border-radius: 25px;
	.MuiChip-deleteIcon {
		color: ${ColorPalette.white};
	}
	.MuiChip-deleteIcon:hover {
		color: ${ColorPalette.white};
	}
`;
