import { FC, useState, useMemo } from 'react';
import { Box, Collapse, Divider, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { format } from 'date-fns';
import { FileType, SavedFileStatus } from 'entities/Files/types';
import { retryImportFile } from 'entities/ImportSessions/api';
import { RecognizedLabelsStructureType } from 'entities/Files/types';
import { AsModalList } from 'widgets/Extensions/Details/ui/AssertionSets/ui/AsModalList';
import { useAssertionSetsStore } from 'widgets/Extensions/Details/ui/AssertionSets/model/state';
import { useCustomModelStore } from 'widgets/customModel/state';
import { useFilesStore } from 'widgets/editFile/model/state';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { usePartnersStore } from 'widgets/Partners/state';
import { useExtension } from 'shared/hooks/useExtension';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import { Icon } from 'shared/ui/Icon';
import { NativeSelect } from 'shared/ui/NativeSelect';
import { ExtensionsEnum } from 'entities/Extensions/enums';
import { PartnersEnum } from 'entities/Partners/enums';
import PartnersModal from '../partners-modal';

interface Props {
	openFileDetails: boolean;
	toggleFileDetails: () => void;
	file?: FileType;
	submitChanges?: any;
}

export const FileDetails: FC<Props> = ({ openFileDetails, toggleFileDetails, file, submitChanges }) => {
	const { t } = useTranslation();
	const { fileId } = useParams();
	const { getExtension } = useExtension();
	const { assertionsSets } = useAssertionSetsStore();
	const { setFileDetails } = useFilesStore();
	const { partners } = usePartnersStore();
	const { setCustomFileDetails } = useCustomModelStore();
	const [isAssertionsOpen, setIsAssertionsOpen] = useState(false);
	const [isPartnersModalOpen, setIsPartnersModalOpen] = useState(false);

	const assertionSet = useMemo(
		() => assertionsSets?.find((item) => item._id === file?.assertionSetsId),
		[assertionsSets, file],
	);

	const partner = useMemo(
		() => partners?.results.find((item) => item._id === file?.partnerId),
		[partners, file],
	);

	const { mutate, isLoading } = useMutation({
		mutationKey: ['patch/files/recognition-result'],
		mutationFn: retryImportFile,
	});

	const updateFileAssertionsHandler = (asId?: string) => {
		const submit: RecognizedLabelsStructureType[] = submitChanges().labels;

		const newRecognized: RecognizedLabelsStructureType[] =
			submit?.map((item) => {
				if (item.type === 'table') {
					const newCells = item.cells.map((cellItem) => ({
						...cellItem,
						assertionSetsId: undefined,
						assertionsData: undefined,
					}));
					return { ...item, cells: newCells };
				}
				return { ...item, assertionSetsId: undefined, assertionsData: undefined };
			}) || [];

		mutate(
			{
				id: file?._id,
				data: {
					recognizedLabels:
						file?.assertionSetsId !== asId ? newRecognized : file?.recognizedLabels || [],
					assertionSetsId: !asId ? null : asId,
					partnerId: file?.partnerId || null,
					isPreview: true,
				},
			},
			{
				onSuccess: (response) => {
					setIsAssertionsOpen(false);
					if (asId) {
						enqueueSnackbar({
							message: t('assertions.modal.success_adding'),
							variant: 'success',
						});
					} else {
						enqueueSnackbar({
							message: t('assertions.modal.success_removing'),
							variant: 'success',
						});
					}
					if (fileId) {
						setFileDetails(response.data);
					} else {
						setCustomFileDetails(response.data);
					}
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	const changePartnerObtaining = (autocomplete: boolean) => {
		mutate(
			{
				id: file?._id,
				data: {
					recognizedLabels: file?.recognizedLabels || [],
					assertionSetsId: file?.assertionSetsId || null,
					partnerId: file?.partnerId || null,
					isPreview: true,
					autocomplete,
				},
			},
			{
				onSuccess: (response) => {
					enqueueSnackbar({
						message: t('partners.snackbar.success_settings_applying'),
						variant: 'success',
					});
					if (fileId) {
						setFileDetails(response.data);
					} else {
						setCustomFileDetails(response.data);
					}
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	const renderFilePreview = (fileStatus: SavedFileStatus | undefined) => {
		switch (fileStatus) {
			case 'RECOGNIZED':
				return (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'flex-start',
						}}
					>
						<Icon
							id={'done_ring_round'}
							color={ColorPalette.jungleGreen}
							style={{ width: '24px', height: '24px', marginRight: '20px' }}
						/>
						<Typography variant={'body2'} color={ColorPalette.astronaut}>
							{t('files.tabs.tab_3')}
						</Typography>
					</Box>
				);
			case 'UNRECOGNIZED':
				return (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
						}}
					>
						<Icon
							id={'dell_fill'}
							color={ColorPalette.violetRed}
							style={{ width: '24px', height: '24px', marginRight: '20px' }}
						/>
						<Typography variant={'body2'} color={ColorPalette.astronaut}>
							{t('files.tabs.tab_1')}
						</Typography>
					</Box>
				);
			default:
				return <></>;
		}
	};

	const fileDetailsContent = useMemo(
		() => [
			{
				lable: t('shared_texts.table_columns.status'),
				content: renderFilePreview(file?.status),
				visible: true,
			},
			{
				lable: t('shared_texts.table_columns.imported_at'),
				content: (
					<>{file?.autoRemoveAt ? format(new Date(file?.autoRemoveAt), 'HH:mm dd/MM/yyyy') : '-'}</>
				),
				visible: true,
			},
			{ lable: t('shared_texts.table_columns.source'), content: file?.uploadSource, visible: true },
			{
				lable: t('shared_texts.table_columns.category'),
				content: t(`sidebar.menu./files/${file?.category}`),
				visible: !!file?.category,
			},
			{
				lable: t('files.details.company_info'),
				content: (
					<NativeSelect
						value={file?.autocomplete ? PartnersEnum.client : PartnersEnum.default}
						placeholder=""
						options={companyInfoOptions}
						onSelect={(e) => changePartnerObtaining(Boolean(e))}
						sx={{ width: '100%' }}
					/>
				),
				visible: true,
			},
			{
				lable: t('partners.title'),
				content: (
					<>
						{partner?.name || (
							<span style={{ color: ColorPalette.violetRed }}>
								{t('shared_texts.not_found')}
							</span>
						)}
						<Box onClick={() => setIsPartnersModalOpen(true)} sx={{ marginLeft: '40px' }}>
							<Icon id={'choose_subcategory'} style={{ cursor: 'pointer' }} />
						</Box>
					</>
				),
				visible: true,
			},
			{
				lable: t('extensions.ex_list.assertions_sets.title'),
				content: (
					<>
						{assertionSet?.name || t('shared_texts.not_found')}
						<Box onClick={() => setIsAssertionsOpen(true)} sx={{ marginLeft: '40px' }}>
							<Icon id={'choose_subcategory'} style={{ cursor: 'pointer' }} />
						</Box>
					</>
				),
				visible: fileId && getExtension(ExtensionsEnum.assertions_sets)?.isEnabled,
			},
		],
		[file, fileId, partner],
	);

	return (
		<>
			<AsModalList
				open={isAssertionsOpen}
				handleApply={updateFileAssertionsHandler}
				handleRemoveAssertions={updateFileAssertionsHandler}
				handleClose={() => setIsAssertionsOpen(false)}
				loading={isLoading}
				defaultAssertionId={file?.assertionSetsId}
			/>
			<PartnersModal
				open={isPartnersModalOpen}
				handleClose={() => setIsPartnersModalOpen(false)}
				file={file}
			/>
			<Box
				sx={{
					'width': '100%',
					'boxShadow': Shadow['2'],
					'background': ColorPalette.white,
					'borderRadius': '20px',
					'overflow': 'hidden',
					'height': 'fit-content',
					'padding': '25px',
					'@media screen and (min-width: 1441px)': {
						padding: '40px',
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						cursor: 'pointer',
					}}
					onClick={() => toggleFileDetails()}
				>
					<Typography variant={'h5'} color={ColorPalette.astronaut}>
						{t('files.details.files')}
					</Typography>
					<Icon id={'custom_arrow_down'} color={ColorPalette.astronaut} />
				</Box>

				<Collapse in={openFileDetails} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
					<Divider color={ColorPalette.periwinkle} sx={{ margin: '20px 0 20px' }} />

					<Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
						{fileDetailsContent.map((item, index) =>
							item.visible ? (
								<Box
									key={`details-${index}`}
									sx={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Typography
										variant={'body2'}
										color={ColorPalette.baliHai}
										sx={{ display: 'flex', alignItems: 'center', flex: '1 1' }}
									>
										{item.lable}
									</Typography>
									<Typography
										variant={'body2'}
										color={ColorPalette.astronaut}
										sx={{ display: 'flex', alignItems: 'center', flex: '1 1' }}
									>
										{item.content}
									</Typography>
								</Box>
							) : null,
						)}
					</Box>
				</Collapse>
			</Box>
		</>
	);
};

const companyInfoOptions = [
	{
		value: PartnersEnum.default,
		label: 'files.select_options.obtained_from_doc',
	},
	{
		value: PartnersEnum.client,
		label: 'files.select_options.obtained_from_partner',
	},
];
