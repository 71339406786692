import { FC, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Dialog, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { postWarehouseItems, putWarehouseItems } from 'entities/Warehouse/api';
import { WarehouseItemType } from 'entities/Warehouse/types';
import { InputField } from 'shared/ui/InputField';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Paper } from 'shared/ui/Paper';
import { Loader } from 'shared/ui/Loader';
import { Button } from 'shared/ui/Button';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { WarehouseValidationSchema } from 'widgets/Extensions/Details/ui/Warehouse/consts/validation';
import { WarehouseFormType } from 'widgets/Extensions/Details/ui/Warehouse/types';
import { usePartnersStore } from 'widgets/Partners/state';
import FormAutocomplete from 'shared/ui/autocomplete/form-autocomplete';

interface ManualModalPropsType {
	articleToEdit?: WarehouseItemType;
	open: boolean;
	handleClose: () => void;
	setSearchPartnersParam: (value: string) => void;
}

export const ManualModal: FC<ManualModalPropsType> = ({
	articleToEdit,
	open,
	handleClose,
	setSearchPartnersParam,
}) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { partners } = usePartnersStore();

	const partnersMemo = useMemo(
		() =>
			partners?.results
				? partners?.results.map((item) => ({
						label: item.name,
						value: item._id,
				  }))
				: [],
		[partners],
	);

	const defaultValueForPartners = useMemo(
		() => partnersMemo?.find((item) => item.value === articleToEdit?.partnerId),
		[partnersMemo, articleToEdit],
	);

	const { mutate: putWarehouseItemsMutate, isLoading: patchIsLoading } = useMutation({
		mutationKey: ['put/warehouse-items'],
		mutationFn: putWarehouseItems,
	});

	const { mutate: postWarehouseItemsMutate, isLoading: postIsLoading } = useMutation({
		mutationKey: ['post/warehouse-items'],
		mutationFn: postWarehouseItems,
	});

	const onCreateWarehouseItemsHandler = (data: WarehouseFormType) => {
		const payload = { ...data, partnerId: data.partnerId?.value || null };
		postWarehouseItemsMutate(
			{ data: payload },
			{
				onSuccess: async () => {
					handleClose();
					setSearchPartnersParam('');
					await queryClient.refetchQueries({ queryKey: ['get/warehouse-items'] });
					enqueueSnackbar({
						message: t('extensions.snackbar.success_warehouse_creation'),
						variant: 'success',
					});
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	const onUpdateWarehouseItemsHandler = (warehouseItemId: string, data: WarehouseFormType) => {
		const payload = { ...data, partnerId: data.partnerId?.value || null };

		putWarehouseItemsMutate(
			{ warehouseItemId, data: payload },
			{
				onSuccess: async () => {
					handleClose();
					setSearchPartnersParam('');
					await queryClient.refetchQueries({ queryKey: ['get/warehouse-items'] });
					enqueueSnackbar({
						message: t('extensions.snackbar.success_warehouse_updating'),
						variant: 'success',
					});
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	const handleManualModalSubmit = (values: WarehouseFormType) => {
		if (articleToEdit) {
			onUpdateWarehouseItemsHandler(articleToEdit._id, values);
		} else {
			onCreateWarehouseItemsHandler(values);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{
				title: (articleToEdit ? t('shared_texts.edit') : t('shared_texts.add')) as string,
			}}
		>
			{!articleToEdit && (
				<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '34px' }}>
					{t('extensions.modals.warehouse_manual_creation_description')}
				</Typography>
			)}
			{patchIsLoading || postIsLoading ? (
				<Loader />
			) : (
				<Formik
					initialValues={{
						code: articleToEdit?.code || '',
						description: articleToEdit?.description || '',
						partnerId: defaultValueForPartners || '',
					}}
					validationSchema={WarehouseValidationSchema}
					onSubmit={handleManualModalSubmit}
				>
					<Form>
						<InputField
							name={'code'}
							label={t('extensions.labels.article_number')}
							placeholder={t('extensions.placeholder.article_description')}
							required
						/>
						<InputField
							name={'description'}
							label={t('extensions.labels.article_description')}
							placeholder={t('extensions.placeholder.article_description')}
							required
							multiline
						/>
						<FormAutocomplete
							name="partnerId"
							defaultValue={defaultValueForPartners}
							options={partnersMemo}
							label={t('partners.labels.client_supplier') as string}
							placeholder={t('partners.placeholder.client_supplier') as string}
							onSearch={(value) => setSearchPartnersParam(value)}
						/>
						<Button type={'submit'} fullWidth>
							{articleToEdit ? t('shared_texts.edit') : t('shared_texts.add')}
						</Button>
					</Form>
				</Formik>
			)}
		</Dialog>
	);
};
