import { PageHeader } from 'shared/ui/PageHeader';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { Typography, Box } from '@mui/material';
import { FormSwitch } from 'shared/ui/Switch/FormSwitch';
import { useAuthStore } from 'processes/auth/model/state';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Button } from 'shared/ui/Button';
import { Paper } from 'shared/ui/Paper';
import { patchPartnerAutoConnect } from 'entities/Partners/api';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';

const ProccessSchema = yup.object({
	autoConnectPartners: yup.bool(),
});

const PartnersProcesses = () => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { company } = useAuthStore();

	const { mutate: updatePartnerAutoConnect, isLoading } = useMutation({
		mutationKey: ['put/partners/categories-settings'],
		mutationFn: patchPartnerAutoConnect,
	});

	const handleSubmit = (data: yup.InferType<typeof ProccessSchema>) => {
		updatePartnerAutoConnect(
			{ autoConnectPartners: !!data.autoConnectPartners },
			{
				onSuccess: async () => {
					await queryClient.refetchQueries({ queryKey: ['validate-token'] });
					enqueueSnackbar({
						message: t('partners.snackbar.success_settings_updating'),
						variant: 'success',
					});
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	return (
		<>
			<PageHeader title={t('partners.process_title')} description={t('partners.process_description')} />

			<Formik
				initialValues={{
					autoConnectPartners: company?.autoConnectPartners || false,
				}}
				validationSchema={ProccessSchema}
				onSubmit={handleSubmit}
			>
				<Form>
					<Box
						sx={{
							'display': 'flex',
							'flexDirection': 'column',
							'alignItems': 'flex-end',
							'gap': 3,
							'@media screen and (min-width: 1441px)': {
								width: '50%',
							},
						}}
					>
						<Paper
							sx={{
								'paddingTop': '30px',
								'paddingBottom': '30px',
								'width': '100%',
								'display': 'flex',
								'flexDirection': 'column',
								'gap': '20px',
								'& > div:not(:last-child)': {
									paddingBottom: '20px',
									borderBottom: `1px solid ${ColorPalette.periwinkle}`,
								},
							}}
						>
							<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
								<FormSwitch name="autoConnectPartners" sx={{ marginRight: '10px' }} />
								<Box>
									<Typography
										variant="body1"
										color={ColorPalette.astronaut}
										sx={{ marginBottom: 1 }}
									>
										{t('partners.process_checkbox_title')}
									</Typography>
									<Typography variant="body1" color={ColorPalette.baliHai}>
										{t('partners.process_checkbox_description')}
									</Typography>
								</Box>
							</Box>
						</Paper>
						<Button disabled={isLoading} size={'small'} type={'submit'}>
							{t('shared_texts.save')}
						</Button>
					</Box>
				</Form>
			</Formik>
		</>
	);
};

export default PartnersProcesses;
