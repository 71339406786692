import { Prefixes, Routes, RoutesMap } from 'app/config/routes/consts';
import { AccessLayout } from 'pages/_layouts/AccessLayout';
import { OutletLayout } from 'pages/_layouts/OutletLayout';
import { PrivacyPolicyLayout } from 'pages/_layouts/PrivacyPolicyLayout';
import { ChangeEmail } from 'pages/auth/ChangeEmail';
import { ChangeEmailEmailSent } from 'pages/auth/ChangeEmailEmailSent';
import { SignUpByInvitation } from 'pages/auth/SignUpByInvitation';
import { CoUserDetailsInfoPage } from 'pages/CoUserDetailsInfoPage';
import { CoUsers } from 'pages/CoUsers';
import { EditFile } from 'pages/EditFile';
import { Files } from 'pages/Files';
import { ImportFilesDirect } from 'pages/importFiles/ImportFilesDirect';
import { ImportFilesFromFTP } from 'pages/importFiles/ImportFilesFromFTP';
import { FtpSettings } from 'pages/integrations/FtpSettings';
import { PrivacyPolicy } from 'pages/PrivacyPolicy';
import { StartRecognition } from 'pages/StartRecognition';
import { UnauthorisedLayout } from 'processes/auth/layouts/UnauthorisedLayout';
import { RouteObject } from 'react-router-dom';
import { Typography } from '@mui/material';
import { CreatePasswordLinkExpired } from 'pages/auth/CreatePasswordLinkExpired';
import { ForgotPasswordEmailSent } from 'pages/auth/ForgotPasswordEmail';
import { SignUpEmailSent } from 'pages/auth/SignUpEmailSent';
import { SignIn } from 'pages/auth/SignIn';
import { RemovedAccount } from 'pages/auth/RemovedAccount';
import { SignUp } from 'pages/auth/SignUp';
import { AuthLayout } from 'pages/_layouts/AuthLayout';
import { RootLayout } from 'pages/_layouts/RootLayout';
import { Error } from 'pages/_error';
import { PersonalAccount } from 'pages/PersonalAccount';
import { ForgotPassword } from 'pages/auth/ForgotPassword';
import { CreatePassword } from 'pages/auth/CreatePassword';
import { CreatePasswordSuccess } from 'pages/auth/CreatePasswordSuccess';
import { SignUpEmailReceived } from 'pages/auth/SignUpEmailReceived';
import { AuthorisedLayout } from 'processes/auth/layouts/AuthorisedLayout';
import { Users } from 'pages/admin/Users';
import { UserDetailsInfoPage } from 'pages/admin/UserDetailsPage';
import { Subscriptions } from 'pages/Subscriptions';
import { Extensions } from 'pages/Extensions';
import { ExtensionsToSelect } from 'pages/Extensions/AllExtensions';
import { ExtensionsDetails } from 'pages/Extensions/Details';
import { CustomModel } from 'widgets/customModel';
import { ImportCustomFilesDirect } from 'pages/importFiles/importCustomFilesDirect/ImportCustomFilesDirect';
import { ImportCustomFilesFromFTP } from 'pages/importFiles/ImportCustomFilesFromFTP/ImportCustomFilesDirect';
import { ImportFilesModelDashboardPage } from 'widgets/customModel/ImportFilesModelDashboard/ImportFilesModelDashboardPage';
import { Resources } from 'pages/admin/Resources';
import { Partners } from 'pages/Partners';
import { PartnersSettings } from 'pages/Partners/Settings';
import { PartnersDocuments } from 'pages/Partners/Documents';
import { SyncSettings } from 'pages/SyncSettings';
import FileSettings from 'pages/Files/file-settings';
import PartnersProcesses from 'pages/Partners/processes';

export const RoutesConfig: RouteObject[] = [
	{
		path: Prefixes.root,
		element: (
			<AuthorisedLayout>
				<RootLayout />
			</AuthorisedLayout>
		),
		children: [
			{
				path: RoutesMap.startRecognition.root,
				element: <StartRecognition />,
			},

			{
				path: Prefixes.startRecognition,
				element: <OutletLayout />,
				children: [
					{
						path: RoutesMap.startRecognition.importFilesDirect,
						element: <ImportFilesDirect />,
					},
					{
						path: RoutesMap.startRecognition.importFilesFromFtp,
						element: <ImportFilesFromFTP />,
					},
				],
			},
			{
				path: Routes.filesWithCategory,
				element: <Files />,
			},
			{
				path: Routes.filesSettings,
				element: <FileSettings />,
			},
			{
				path: Routes.editFileRoot,
				element: <EditFile />,
			},
			{
				path: Routes.editModelFileRoot,
				element: <EditFile />,
			},
			{
				path: RoutesMap.customModel.root,
				element: <CustomModel />,
			},
			{
				path: Routes.importCustomFilesRoot,
				element: <ImportFilesModelDashboardPage />,
			},
			{
				path: Routes.importFilesCustomModel,
				element: <ImportCustomFilesDirect />,
			},
			{
				path: Routes.importFilesFromFtpCustomModel,
				element: <ImportCustomFilesFromFTP />,
			},
			{
				path: RoutesMap.subscriptions,
				element: <Subscriptions />,
			},
			{
				path: Prefixes.extensions,
				element: <OutletLayout />,
				children: [
					{
						path: RoutesMap.extensions.root,
						element: <Extensions />,
					},
					{
						path: Routes.extensionsList,
						element: <ExtensionsToSelect />,
					},
					{
						path: RoutesMap.extensions.exDetails,
						element: <ExtensionsDetails />,
					},
				],
			},
			{
				path: Prefixes.coUsers,
				element: <OutletLayout />,
				children: [
					{
						path: RoutesMap.coUsers.root,
						element: <CoUsers />,
					},
					{
						path: RoutesMap.coUsers.details,
						element: <CoUserDetailsInfoPage />,
					},
				],
			},
			{
				path: Prefixes.partners,
				element: <OutletLayout />,
				children: [
					{
						path: Routes.partners,
						element: <Partners />,
					},
					{
						path: Routes.partnersSettings,
						element: <PartnersProcesses />,
					},
					{
						path: Routes.partnersSettingsWithId,
						element: <PartnersSettings />,
					},
					{
						path: Routes.partnersDocumentsWithId,
						element: <PartnersDocuments />,
					},
				],
			},

			{
				path: Prefixes.syncSettings,
				element: <OutletLayout />,
				children: [
					{
						path: Routes.syncSettings,
						element: <SyncSettings />,
					},
				],
			},
			{
				path: RoutesMap.personalAccount,
				element: <PersonalAccount />,
			},
			// {
			// 	path: `${Prefixes.integrations}${RoutesMap.integrations.apiSettings}`,
			// 	element: <Typography variant={'h4'}>{'Integrations > API settings'}</Typography>,
			// },
			{
				path: `${Prefixes.integrations}${RoutesMap.integrations.ftpSettings}`,
				element: <FtpSettings />,
			},
			{
				path: RoutesMap.support,
				element: <Typography variant={'h4'}>Support</Typography>,
			},
			// {
			// 	path: RoutesMap.playground,
			// 	element: <Playground />,
			// },
			{
				path: Prefixes.users,
				element: <OutletLayout />,
				children: [
					{
						path: RoutesMap.users.root,
						element: <Users />,
					},
					{
						path: RoutesMap.users.details,
						element: <UserDetailsInfoPage />,
					},
				],
			},
			{
				path: Prefixes.resources,
				element: <Resources />,
			},
		],
	},

	{
		path: Prefixes.root,
		element: (
			<UnauthorisedLayout>
				<AuthLayout />
			</UnauthorisedLayout>
		),
		children: [
			{
				path: Prefixes.signUp,
				element: <OutletLayout />,
				children: [
					{
						path: RoutesMap.signUp.root,
						element: <SignUp />,
					},
					{
						path: RoutesMap.signUp.emailSent,
						element: <SignUpEmailSent />,
					},
					{
						path: RoutesMap.signUp.emailReceived,
						element: <SignUpEmailReceived />,
					},
				],
			},
			{
				path: Prefixes.signIn,
				element: <OutletLayout />,
				children: [
					{
						path: RoutesMap.signIn.root,
						element: <SignIn />,
					},
					{
						path: RoutesMap.signIn.removedAccount,
						element: <RemovedAccount />,
					},
				],
			},
		],
	},
	{
		path: Prefixes.root,
		element: <AuthLayout />,
		children: [
			{
				path: `${Prefixes.signUp}${RoutesMap.signUp.byInvitation}`,
				element: <SignUpByInvitation />,
			},
			{
				path: Prefixes.forgotPassword,
				element: <OutletLayout />,
				children: [
					{
						path: RoutesMap.forgotPassword.root,
						element: (
							<AccessLayout>
								<ForgotPassword />
							</AccessLayout>
						),
					},
					{
						path: RoutesMap.forgotPassword.emailSent,
						element: (
							<AccessLayout>
								<ForgotPasswordEmailSent />
							</AccessLayout>
						),
					},
				],
			},
			{
				path: Prefixes.createPassword,
				element: <OutletLayout />,
				children: [
					{
						path: RoutesMap.createPassword.root,
						element: <CreatePassword />,
					},
					{
						path: RoutesMap.createPassword.linkExpired,
						element: (
							<AccessLayout>
								<CreatePasswordLinkExpired />
							</AccessLayout>
						),
					},
					{
						path: RoutesMap.createPassword.success,
						element: (
							<AccessLayout>
								<CreatePasswordSuccess />
							</AccessLayout>
						),
					},
				],
			},
			{
				path: Prefixes.changeEmail,
				element: (
					<AuthorisedLayout>
						<OutletLayout />
					</AuthorisedLayout>
				),
				children: [
					{
						path: RoutesMap.changeEmail.root,
						element: (
							<AccessLayout>
								<ChangeEmail />
							</AccessLayout>
						),
					},
					{
						path: RoutesMap.changeEmail.emailSent,
						element: (
							<AccessLayout>
								<ChangeEmailEmailSent />
							</AccessLayout>
						),
					},
				],
			},
		],
	},
	{
		path: Prefixes.root,
		element: <PrivacyPolicyLayout />,
		children: [
			{
				path: RoutesMap.privacyPolicy,
				element: <PrivacyPolicy />,
			},
		],
	},
	{
		path: '*',
		element: <Error />,
	},
];
