import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterDrawer } from 'shared/ui/FilterDrawer';
import { useCoUsersStore } from 'store/co-users';
import { DateRangeInput } from 'shared/ui/DateRangePicker';
import { Select } from 'shared/ui/Select';
import { Button } from 'shared/ui/Button';
import { GetCoUsersReq } from 'entities/CoUser/api/types';

const CoUserFilters = () => {
	const { t } = useTranslation();
	const { dealersList, coUsersFilters, setCoUsersFilters } = useCoUsersStore();

	const [filters, setFilters] = useState<GetCoUsersReq | undefined>();
	const [isOpenDrawer, setIsOpenDrawer] = useState(false);

	const dealersOptions = useMemo(
		() => dealersList?.map((item) => ({ value: item, label: item })) || [],
		[dealersList],
	);

	const applyFilters = () => {
		if (filters) {
			setIsOpenDrawer(false);
			setCoUsersFilters(filters);
		}
	};

	useEffect(() => {
		setFilters(coUsersFilters);
	}, [coUsersFilters]);

	return (
		<FilterDrawer
			isOpen={isOpenDrawer}
			handleOpen={() => setIsOpenDrawer(true)}
			handleClose={() => setIsOpenDrawer(false)}
		>
			<DateRangeInput
				name={'period'}
				label={t('shared_texts.select_period')}
				placeholder={t('shared_texts.placeholder.date') as string}
				startDate={filters?.from}
				endDate={filters?.to}
				changeRange={(from, to) => {
					setFilters({
						...filters,
						from,
						to,
					});
				}}
			/>
			<Select
				label={t('shared_texts.table_columns.dealer_name') as string}
				value={filters?.dealerName || ''}
				placeholder={t('co_users.placeholder.dealer_name')}
				onSelect={(value) => {
					setFilters({
						...filters,
						dealerName: value as string,
					});
				}}
				options={dealersOptions}
			/>

			<Button onClick={applyFilters} variant={'contained'} fullWidth>
				{t('shared_texts.apply_filters')}
			</Button>
		</FilterDrawer>
	);
};

export default CoUserFilters;
