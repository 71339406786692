import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getPartners } from 'entities/Partners/api';
import { getWarehouseItems, getWarehouseProcesses } from 'entities/Warehouse/api';
import { Routes } from 'app/config/routes/consts';
import { PageHeader } from 'shared/ui/PageHeader';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { usePartnersStore } from 'widgets/Partners/state';
import { useWarehouseStore } from 'widgets/Extensions/Details/ui/Warehouse/state';
import {
	WarehouseFilterAndSearch,
	WarehouseTabsEnum,
} from 'widgets/Extensions/Details/ui/Warehouse/ui/WarehouseFilterAndSearch';
import { ArticlesTab } from 'widgets/Extensions/Details/ui/Warehouse/ui/Tabs/ArticlesTab';
import { ProcessesTab } from 'widgets/Extensions/Details/ui/Warehouse//ui/Tabs/ProcessesTab';
import { SelectArticleCreationModal } from 'widgets/Extensions/Details/ui/Warehouse/ui/Modals/create/SelectCreationModal';
import { ImportCsvModal } from 'widgets/Extensions/Details/ui/Warehouse/ui/Modals/create/ImportCsvModal';
import { AfterCsvCreationModal } from 'shared/ui/AfterCsvCreationModal';

export const WarehouseWidget: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { setWarehouseListData, setProcessesData } = useWarehouseStore();
	const { setPartnersData } = usePartnersStore();
	const [isOpenSelectCreationModal, setIsOpenSelectCreationModal] = useState(false);
	const [searchParam, setSearchParam] = useState<string | null>(null);
	const [searchPartnersParam, setSearchPartnersParam] = useState<string | null>(null);
	const [activeTab, setActiveTab] = useState(WarehouseTabsEnum.warehouses);
	const [isImportCsvModalOpen, setIsImportCsvModalOpen] = useState(false);
	const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);
	const [isManualModalForCreationOpen, setIsManualModalForCreationOpen] = useState(false);
	const [page, setTablePage] = useState(1);

	const { isLoading: getItemsIsLoading } = useQuery({
		queryKey: ['get/warehouse-items', searchParam, page],
		queryFn: () => getWarehouseItems({ search: searchParam, page, perPage: 20 }),
		onSuccess: (response) => {
			setWarehouseListData(response.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	const { isLoading: getProcessIsLoading } = useQuery({
		queryKey: ['get/warehouse-process'],
		queryFn: () => getWarehouseProcesses(),
		onSuccess: (response) => {
			setProcessesData(response.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	useQuery({
		queryKey: ['get/partners', searchPartnersParam],
		queryFn: () => getPartners({ type: null, search: searchPartnersParam }),
		onSuccess: (response) => {
			setPartnersData(response.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	const renderTabs = () => {
		switch (activeTab) {
			case WarehouseTabsEnum.warehouses:
				return (
					<ArticlesTab
						isLoading={getItemsIsLoading}
						isManualModalForCreationOpen={isManualModalForCreationOpen}
						setIsManualModalForCreationOpen={setIsManualModalForCreationOpen}
						setSearchPartnersParam={setSearchPartnersParam}
						onPageChange={(page) => setTablePage(page)}
						isWarehouse
					/>
				);
			case WarehouseTabsEnum.process:
				return <ProcessesTab isLoading={getProcessIsLoading} />;
			default:
				return null;
		}
	};

	const onOpenManualModalHandler = () => {
		setIsOpenSelectCreationModal(false);
		setIsManualModalForCreationOpen(true);
	};

	const onOpenImportCsvModalHandler = () => {
		setIsOpenSelectCreationModal(false);
		setIsImportCsvModalOpen(true);
	};

	return (
		<>
			<PageHeader
				title={t('extensions.ex_list.warehouse.title')}
				description={t('extensions.ex_list.warehouse.description')}
				backBtn
				onClick={() => navigate(Routes.extensions)}
			/>
			<WarehouseFilterAndSearch
				activeTab={activeTab}
				setTab={setActiveTab}
				setSearch={setSearchParam}
				createAction={() => setIsOpenSelectCreationModal(true)}
				setTablePage={() => setTablePage(1)}
			/>
			<SelectArticleCreationModal
				open={isOpenSelectCreationModal}
				handleClose={() => setIsOpenSelectCreationModal(false)}
				openManualModal={onOpenManualModalHandler}
				openImportCsvModal={onOpenImportCsvModalHandler}
			/>

			<ImportCsvModal
				open={isImportCsvModalOpen}
				handleClose={() => setIsImportCsvModalOpen(false)}
				setIsOpenInfoModal={() => setIsOpenInfoModal(true)}
			/>
			<AfterCsvCreationModal open={isOpenInfoModal} handleClose={() => setIsOpenInfoModal(false)} />
			{renderTabs()}
		</>
	);
};
