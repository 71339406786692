import { FunctionComponent } from 'react';
import {
	Autocomplete as MuiAutocomplete,
	TextField,
	styled,
	InputBaseProps,
	FormControl,
} from '@mui/material';
import * as _ from 'lodash';

import { ColorPalette } from 'shared/consts/colorPalette';
import { InputError } from 'shared/ui/InputError';
import { InputLabel } from 'shared/ui/InputLabel';
import { SelectOption } from 'shared/ui/Select/types';

type AutocompleteOption = {
	value: string | undefined | null;
	label: React.ReactNode | string;
};

export type AutocompleteProps = InputBaseProps & {
	name: string;
	label: string;
	errorText?: string;
	options: AutocompleteOption[];
	onChange?: (newVal: AutocompleteOption | AutocompleteOption[] | null) => void;
	onSearch?: (value: string) => void;
	value?: AutocompleteOption | AutocompleteOption[];
	defaultValue?: AutocompleteOption | AutocompleteOption[];
};

const Autocomplete: FunctionComponent<AutocompleteProps> = ({
	sx,
	name,
	label,
	required,
	fullWidth = true,
	errorText,
	placeholder,
	defaultValue,
	value,
	options,
	onChange,
	onSearch,
}) => {
	const handleSearch = _.debounce((e, value: string) => {
		onSearch && e && onSearch(value);
	}, 500);

	return (
		<MuiAutocomplete
			onInputChange={handleSearch}
			options={options}
			defaultValue={defaultValue}
			onChange={(e, newVal) => onChange && onChange(newVal as SelectOption | SelectOption[])}
			renderInput={(params) => {
				return (
					<FormControl
						variant={'standard'}
						fullWidth={fullWidth}
						sx={{ marginBottom: '40px', ...sx }}
					>
						{label && (
							<InputLabel
								name={name}
								label={label}
								required={required}
								sx={{ position: 'relative' }}
							/>
						)}
						<WhiteBorderTextField
							name={name}
							placeholder={placeholder}
							sx={{
								input: {
									color: ColorPalette.astronaut,
									fontWeight: 600,
									fontSize: '14px',
									lineHeight: '18px',
								},
							}}
							{...params}
						/>
						<InputError text={errorText} />
					</FormControl>
				);
			}}
		/>
	);
};

export default Autocomplete;

const WhiteBorderTextField = styled(TextField)`
	fieldset {
		border: 1px solid ${ColorPalette.periwinkle};
		border-radius: 10px;
	}

	& .MuiOutlinedInput-root {
		&.Mui-focused fieldset {
			border: 1px solid ${ColorPalette.periwinkle};
		}
		&:hover fieldset {
			border: 1px solid ${ColorPalette.periwinkle};
		}
	}
`;
