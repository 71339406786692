import { useEffect, useState, FC, ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, styled, Typography } from '@mui/material';
import { Select } from 'shared/ui/Select';
import { useDebounce } from 'shared/helpers/useDebounce';
import { useSearchParam } from 'shared/helpers/useSearchParam';
import { SearchInput } from 'shared/ui/SearchInput';
import { FilterDrawer } from 'shared/ui/FilterDrawer';
import { DateRangeInput } from 'shared/ui/DateRangePicker';
import { Button } from 'shared/ui/Button';
import { GetUsersReq, RangeProps } from 'entities/admin/Users/api/types';
import { featuresArray } from 'widgets/admin/UserDetails/ui/tabs/AdditionalFeatures';

interface Props {
	filters: GetUsersReq;
	setFilters: (filters: GetUsersReq) => void;
}

const UsersFilter: FC<Props> = ({ filters, setFilters }) => {
	const { t } = useTranslation();
	const [createdAtRange, setCreatedAtRange] = useState<RangeProps>({
		createdAtFrom: undefined,
		createdAtTo: undefined,
	});
	const [subscriptionType, setSubscriptionType] = useState<string[] | undefined>([]);
	const [additionalFeatures, setAdditionalFeatures] = useState<string[] | undefined>([]);

	const [searchParam, setSearchParam] = useSearchParam('search_user', '');
	const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
	const [searchInputValue, setSearchInputValue] = useState<string>(searchParam ?? '');
	const debouncedSearchInputValue = useDebounce<string>(searchInputValue, 500);

	const subFilterArr = [
		{
			value: 'unlimited',
			label: 'Unlimited',
		},
		{
			value: 'free',
			label: 'Free',
		},
		{
			value: 'starter',
			label: 'Starter',
		},
		{
			value: 'pro',
			label: 'Pro',
		},
		{
			value: 'pay-as-you-go',
			label: 'Pay as you go',
		},
	];

	const featFilterArr = useMemo(
		() =>
			featuresArray.map((item) => ({
				value: item.feature,
				label: `custom_model.model_types.${item.feature}`,
			})),
		[],
	);

	useEffect(() => {
		setSearchParam(debouncedSearchInputValue);
	}, [debouncedSearchInputValue]);

	useEffect(() => {
		setFilters({
			...filters,
			search: searchParam,
		});
	}, [searchParam]);

	const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchInputValue(event.target.value);
	};

	const submitFilters = () => {
		setIsOpenDrawer(false);
		setFilters({
			...filters,
			...createdAtRange,
			subscriptionType,
			additionalFeatures,
		});
	};

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '44px' }}>
				<SearchInput value={searchInputValue} onChange={handleSearchInputChange} />
				<FilterDrawer
					isOpen={isOpenDrawer}
					handleOpen={() => setIsOpenDrawer(true)}
					handleClose={() => setIsOpenDrawer(false)}
				>
					<DateRangeInput
						name={'Period of registration date'}
						label={t('admin.users.labels.period_of_registration_date')}
						placeholder={t('shared_texts.placeholder.date') as string}
						startDate={createdAtRange.createdAtFrom}
						endDate={createdAtRange.createdAtTo}
						changeRange={(startDate, endDate) => {
							setCreatedAtRange({
								createdAtFrom: startDate,
								createdAtTo: endDate,
							});
						}}
					/>
					<Select
						label={t('admin.users.labels.subscription') as string}
						value={subscriptionType ?? []}
						placeholder={t('admin.users.placeholder.subscription')}
						onSelect={(value) => {
							setSubscriptionType([...(value as string[])]);
						}}
						options={subFilterArr}
						multiple={true}
					/>
					<Select
						label={t('admin.users.labels.additional_features') as string}
						value={additionalFeatures ?? []}
						placeholder={t('admin.users.placeholder.additional_features')}
						onSelect={(value) => {
							setAdditionalFeatures([...(value as string[])]);
						}}
						options={featFilterArr}
						multiple={true}
					/>
					<Button onClick={submitFilters} variant={'contained'} fullWidth>
						{t('shared_texts.apply_filters')}
					</Button>
				</FilterDrawer>
			</Box>
			<Box sx={{ display: 'flex', gap: '14px' }}>
				{/* TODO neeed to REFACTOR  */}
				{filters?.createdAtFrom && filters?.createdAtTo && (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
						<Typography>{t('admin.users.labels.period_of_registration_date')}: </Typography>
						<ChipStyled
							label={`${filters?.createdAtFrom
								.slice(0, 10)
								.replaceAll('-', '/')} - ${filters?.createdAtTo
								.slice(0, 10)
								.replaceAll('-', '/')}`}
							onDelete={() => {
								setCreatedAtRange({
									createdAtFrom: undefined,
									createdAtTo: undefined,
								});
								setFilters({
									...filters,
									createdAtFrom: undefined,
									createdAtTo: undefined,
								});
							}}
						/>
					</Box>
				)}
				{filters?.subscriptionType && filters?.subscriptionType?.length > 0 && (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
						<Typography>{t('admin.users.labels.subscription')}: </Typography>
						<Box sx={{ display: 'flex' }}>
							{filters?.subscriptionType.map((statusItem) => {
								return (
									<ChipStyled
										key={statusItem}
										label={`${statusItem}`}
										onDelete={() => {
											const newSubscriptionType =
												filters?.subscriptionType &&
												filters?.subscriptionType.filter(
													(item) => item !== statusItem,
												);
											setSubscriptionType(newSubscriptionType);

											setFilters({
												...filters,
												subscriptionType: newSubscriptionType,
											});
										}}
									/>
								);
							})}
						</Box>
					</Box>
				)}

				{filters?.additionalFeatures && filters?.additionalFeatures?.length > 0 && (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
						<Typography>{t('admin.users.labels.additional_features')}: </Typography>
						<Box sx={{ display: 'flex' }}>
							{filters?.additionalFeatures.map((statusItem) => {
								return (
									<ChipStyled
										key={statusItem}
										label={`${statusItem}`}
										onDelete={() => {
											const newFeatType =
												filters?.additionalFeatures &&
												filters?.additionalFeatures.filter(
													(item) => item !== statusItem,
												);
											setAdditionalFeatures(newFeatType);

											setFilters({
												...filters,
												additionalFeatures: newFeatType,
											});
										}}
									/>
								);
							})}
						</Box>
					</Box>
				)}
				{/* ----------- */}
			</Box>
		</>
	);
};

export default UsersFilter;

const ChipStyled = styled(Chip)`
	background: #908ffa;
	padding: 0 8px 0 16px;
	height: 45px;
	border-radius: 22.5px;
	color: #fff;
	margin-right: 20px;

	box-shadow: 4.65909px 4.65909px 18.63636px 0 #babecc, -7.45455px -7.45455px 9.31818px 0px #fff;

	.MuiChip-deleteIcon {
		color: #fff;
	}

	.MuiChip-deleteIcon:hover {
		color: rgba(255, 255, 255, 0.7);
	}
`;
