import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	TableBody as MUITableBody,
	Box,
	TableCell,
	TableProps,
	TableRow,
	Collapse,
	styled,
} from '@mui/material';
import { IconButton } from 'shared/ui/IconButton';
import { ColorPalette } from 'shared/consts/colorPalette';
import { prepareCell } from 'shared/ui/tables/_common/helpers/prepareCell';
import { Column } from 'shared/ui/tables/_common/types';

interface Props<T> extends TableProps {
	columns: Array<Column<T>>;
	rows: Array<T & { id: string; onRowChange?: () => void }>;
}
export function TableBody<T>({ columns, rows }: Props<T>) {
	return (
		<MUITableBody
			sx={{
				'& > * + *': {
					borderTop: `1.5px solid ${ColorPalette.zircon}`,
				},
			}}
		>
			{rows.map((row) => (
				<Row key={row.id} columns={columns} row={row} />
			))}
		</MUITableBody>
	);
}

interface RowProps<T> extends TableProps {
	columns: Array<Column<T>>;
	row: T & { id: string; onRowChange?: () => void };
}

function Row<T>({ columns, row }: RowProps<T>) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	return (
		<>
			<TableRow
				sx={{
					'position': 'relative',
					'margin': '0',
					'&:hover': { backgroundColor: ColorPalette.zircon },
					'cursor': 'pointer',
				}}
				onClick={() => {
					row.onRowChange && row.onRowChange();
				}}
			>
				{columns.map((column) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore

					let prepareFieldFn, renderCellFn, restBodyCellSettings;
					if (column?.bodyCellSettings) {
						const { prepareField, renderCell, ...rest } = column.bodyCellSettings;
						prepareFieldFn = prepareField;
						renderCellFn = renderCell;
						restBodyCellSettings = rest;
					}

					const preparedCell = prepareCell(row, prepareFieldFn, renderCellFn);
					if (column.field === 'toggle_collapse') {
						if (preparedCell) {
							return (
								<TableCellStyled
									key={column.field}
									{...column?.commonCellSettings}
									{...restBodyCellSettings}
								>
									<IconButton
										iconId={open ? 'custom_arrow_up' : 'custom_arrow_down'}
										onClick={() => setOpen(!open)}
										sxWrapper={{ boxShadow: 'none' }}
									/>
								</TableCellStyled>
							);
						}
						return (
							<TableCellStyled
								key={column.field}
								{...column?.commonCellSettings}
								{...restBodyCellSettings}
							/>
						);
					}
					return preparedCell !== null ? (
						<TableCellStyled
							key={column.field}
							{...column?.commonCellSettings}
							{...restBodyCellSettings}
						>
							{Array.isArray(preparedCell) && preparedCell.length > 1
								? t('shared_texts.multiple')
								: preparedCell}
						</TableCellStyled>
					) : null;
				})}
			</TableRow>
			<TableRow>
				<TableCell
					style={{ padding: 0, border: 'none', backgroundColor: 'rgb(242 249 255)' }}
					colSpan={12}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<TableRow
							sx={{
								width: '100%',
								display: 'inline-table',
							}}
						>
							{columns.map((column) => {
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore

								let prepareFieldFn, renderCellFn, restBodyCellSettings;
								if (column?.bodyCellSettings) {
									const { prepareField, renderCell, ...rest } = column.bodyCellSettings;
									prepareFieldFn = prepareField;
									renderCellFn = renderCell;
									restBodyCellSettings = rest;
								}

								const preparedCell = prepareCell(row, prepareFieldFn, renderCellFn);
								if (Array.isArray(preparedCell)) {
									return (
										<TableCellStyled
											key={column.field}
											{...column?.commonCellSettings}
											{...restBodyCellSettings}
										>
											<Box
												sx={{
													height: '100%',
													display: 'grid',
												}}
											>
												{preparedCell.map((item, i) => (
													<Box
														key={i}
														sx={{
															borderBottom:
																preparedCell.length !== i + 1
																	? `1px solid ${ColorPalette.periwinkle}`
																	: '',
															padding: '10px 0',
															height: '48px',
															display: '-webkit-box',
															WebkitBoxOrient: 'vertical',
															overflow: 'hidden',
															WebkitLineClamp: 2,
														}}
													>
														{item}
													</Box>
												))}
											</Box>
										</TableCellStyled>
									);
								}
								return (
									<TableCellStyled
										key={column.field}
										{...column?.commonCellSettings}
										{...restBodyCellSettings}
									/>
								);
							})}
						</TableRow>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}

const TableCellStyled = styled(TableCell)`
	padding: 0 10px;
	border: none;
	height: 81px;
	color: ${ColorPalette.astronaut};
	overflow: hidden;
	text-overflow: ellipsis;
	&:first-of-type {
		padding-left: 24px;
	}
	&:last-of-type {
		padding-right: 24px;
	}
`;
