import { Box } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCoUsers } from 'entities/CoUser/api';
import { getCompanyUsersInfo } from 'entities/User/api';
import { UserRoles } from 'entities/User/types';
import { useAuthStore } from 'processes/auth/model/state';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { CategoryTypeEnum } from 'entities/Files/types';
import { useDebounce } from 'shared/helpers/useDebounce';
import { SearchParams } from 'shared/helpers/useFilesSearchParams';
import { Button } from 'shared/ui/Button';
import { DateRangeInput } from 'shared/ui/DateRangePicker';
import { SearchInput } from 'shared/ui/SearchInput';
import { Select } from 'shared/ui/Select';
import { SelectOption } from 'shared/ui/Select/types';
import { TabButton } from 'shared/ui/TabButton';
import { FilterDrawer } from 'shared/ui/FilterDrawer';
import { SavedFileStatus } from 'entities/Files/types';
import { OptionsDropdown } from 'shared/ui/OptionsDropdown';
import { Routes } from 'app/config/routes/consts';

interface Props {
	category?: string;
	filesCounters?: { unrecognized: number; recognized: number; inProcess: number };
	searchParams: SearchParams;
	changeSearchParams: (searchParams: Partial<SearchParams>) => void;
	handleToggleAllFiles: (refresh?: boolean) => void;
}

interface IUsers {
	_id: string;
	firstName: string;
	lastName: string;
}

export const FiltersAndSearch: FC<Props> = ({
	category,
	filesCounters,
	searchParams,
	changeSearchParams,
	handleToggleAllFiles,
}) => {
	const navigate = useNavigate();
	const { partnerId } = useParams();
	const { t } = useTranslation();
	const { user } = useAuthStore();
	const { data: coUsersData } = useQuery({
		queryKey: ['co-users'],
		queryFn: () => getCoUsers(),
		enabled: user?.role === UserRoles.user,
	});

	const CoUserSelectOptions: Array<SelectOption> = useMemo(() => {
		const coUserSelectOptions: Array<SelectOption> = [
			{
				value: 'nobody',
				label: 'Nobody',
			},
		];
		//eslint-disable-next-line @typescript-eslint/no-explicit-any
		getCompanyUsersInfo().then((res: any) => {
			res.data.forEach((user: IUsers) => {
				coUserSelectOptions.push({
					value: user._id,
					label: `${user.firstName} ${user.lastName}`,
				});
			});
		});
		return coUserSelectOptions;
	}, [coUsersData?.data]);

	const [filterMenu, setFilterMenu] = useState<boolean>(false);

	const handleCloseFilterMenu = () => setFilterMenu(false);

	const [searchInputValue, setSearchInputValue] = useState<string>(searchParams.search);
	const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchInputValue(event.target.value);
	};
	const debouncedSearchInputValue = useDebounce<string>(searchInputValue, 500);
	useEffect(() => {
		handleToggleAllFiles(true);
		changeSearchParams({ search: debouncedSearchInputValue });
	}, [debouncedSearchInputValue]);

	const [sortFilter, setSortFilter] = useState<SearchParams['sort']>(searchParams.sort);
	const [uploadSourceFilter, setUploadSourceFilter] = useState<SearchParams['uploadSource']>(
		searchParams.uploadSource,
	);
	const [categoryFilter, setCategoryFilter] = useState<SearchParams['category']>(searchParams.category);
	const [coUserIdFilter, setCoUserIdFilter] = useState<SearchParams['coUserId']>(searchParams.coUserId);
	const [importRange, setImportRange] = useState<{
		from?: SearchParams['importFrom'];
		to?: SearchParams['importTo'];
	}>({
		from: searchParams.importFrom,
		to: searchParams.importFrom,
	});
	const [autoRemoveRange, setAutoRemoveRange] = useState<{
		from?: SearchParams['autoRemoveFrom'];
		to?: SearchParams['autoRemoveTo'];
	}>({
		from: searchParams.autoRemoveFrom,
		to: searchParams.autoRemoveTo,
	});

	const handleOpenFilterMenu = () => {
		setFilterMenu(true);
	};

	const applyFilters = () => {
		handleToggleAllFiles(true);
		changeSearchParams({
			sort: sortFilter,
			uploadSource: uploadSourceFilter,
			category: categoryFilter,
			coUserId: coUserIdFilter,
			importFrom: importRange.from,
			importTo: importRange.to,
			autoRemoveFrom: autoRemoveRange.from,
			autoRemoveTo: autoRemoveRange.to,
		});
	};

	const switchTabHandler = async (status: SavedFileStatus) => {
		handleToggleAllFiles(true);
		changeSearchParams({ status });
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', marginBottom: '30px' }}>
			<TabButton
				label={`${t('files.tabs.tab_1')} (${filesCounters?.unrecognized ?? '0'})`}
				isActive={searchParams.status === 'UNRECOGNIZED'}
				action={() => switchTabHandler('UNRECOGNIZED')}
			/>
			<TabButton
				label={`${t('files.tabs.tab_2')} (${filesCounters?.inProcess ?? '0'})`}
				isActive={searchParams.status === 'IN_PROCESS'}
				action={() => switchTabHandler('IN_PROCESS')}
			/>
			<TabButton
				label={`${t('files.tabs.tab_3')} (${filesCounters?.recognized ?? '0'})`}
				isActive={searchParams.status === 'RECOGNIZED'}
				action={() => switchTabHandler('RECOGNIZED')}
			/>
			{!partnerId && (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						width: '100%',
						gap: '20px',
					}}
				>
					<SearchInput value={searchInputValue} onChange={handleSearchInputChange} />
					<FilterDrawer
						isOpen={filterMenu}
						handleOpen={handleOpenFilterMenu}
						handleClose={handleCloseFilterMenu}
					>
						<Select
							label={t('files.labels.order') as string}
							value={sortFilter}
							placeholder={t('files.placeholder.order')}
							onSelect={(value) => setSortFilter(value as SearchParams['sort'])}
							options={SortSelectOptions}
						/>
						{category === 'all' && (
							<Select
								label={t('files.labels.category') as string}
								value={categoryFilter}
								placeholder={t('files.placeholder.category')}
								onSelect={(value) => setCategoryFilter(value as SearchParams['category'])}
								options={CategoryFilterSelectOptions}
								multiple={true}
							/>
						)}
						<DateRangeInput
							name={'By the importing date/period'}
							label={t('files.labels.import_date')}
							placeholder={t('shared_texts.placeholder.date') as string}
							startDate={importRange.from}
							endDate={importRange.to}
							changeRange={(startDate, endDate) => {
								setImportRange((prevState) => ({
									...prevState,
									from: startDate,
									to: endDate,
								}));
							}}
						/>
						<Select
							label={t('files.labels.import_option') as string}
							value={uploadSourceFilter}
							placeholder={t('files.placeholder.import_option')}
							onSelect={(value) => setUploadSourceFilter(value as SearchParams['uploadSource'])}
							options={UploadSourceFilterSelectOptions}
						/>
						<DateRangeInput
							name={'Period of automatic removal'}
							label={t('files.labels.removal_date')}
							placeholder={t('shared_texts.placeholder.date') as string}
							startDate={autoRemoveRange.from}
							endDate={autoRemoveRange.to}
							changeRange={(startDate, endDate) => {
								setAutoRemoveRange((prevState) => ({
									...prevState,
									from: startDate,
									to: endDate,
								}));
							}}
						/>
						{user?.role === UserRoles.user && (
							<Select
								label={t('files.labels.import_user') as string}
								value={coUserIdFilter}
								placeholder={t('files.placeholder.import_user')}
								onSelect={(value) => setCoUserIdFilter(value as SearchParams['coUserId'])}
								options={CoUserSelectOptions}
							/>
						)}
						<Button
							onClick={() => {
								applyFilters();
								handleCloseFilterMenu();
							}}
							variant={'contained'}
							fullWidth
						>
							{t('shared_texts.apply_filters')}
						</Button>
					</FilterDrawer>
					<OptionsDropdown
						iconId={'setting_line_fill'}
						optionList={[
							{
								label: t('files.select_options.manage_export'),
								value: 'manage_export',
								action: () => navigate(Routes.filesSettings),
							},
						]}
					/>
				</Box>
			)}
		</Box>
	);
};

const SortSelectOptions: Array<SelectOption> = [
	{
		value: 'asc',
		label: 'files.select_options.by_the_oldest_the_newest',
	},
	{
		value: 'desc',
		label: 'files.select_options.by_the_newest_the_oldest',
	},
];

const UploadSourceFilterSelectOptions: Array<SelectOption> = [
	{
		value: 'all',
		label: 'All',
	},
	{
		value: 'DIRECT',
		label: 'Direct',
	},
	{
		value: 'FTP',
		label: 'FTP',
	},
];

const CategoryFilterSelectOptions: Array<SelectOption> = [
	{
		value: CategoryTypeEnum.purchase_orders,
		label: 'sidebar.menu./files/purchase-orders',
	},
	{
		value: CategoryTypeEnum.sales_orders,
		label: 'sidebar.menu./files/sales-orders',
	},
	{
		value: CategoryTypeEnum.receipts,
		label: 'sidebar.menu./files/receipts',
	},
	{
		value: CategoryTypeEnum.general_documents,
		label: 'sidebar.menu./files/general-documents',
	},
	{
		value: CategoryTypeEnum.invoices,
		label: 'sidebar.menu./files/invoices',
	},
	{
		value: CategoryTypeEnum.transport_documents,
		label: 'sidebar.menu./files/transport-documents',
	},
	{
		value: CategoryTypeEnum.custom,
		label: 'sidebar.menu./files/custom',
	},
];
