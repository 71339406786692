import { FC, useMemo, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import * as yup from 'yup';
import { Dialog, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getPartners } from 'entities/Partners/api';
import { retryImportFile } from 'entities/ImportSessions/api';
import { FileType } from 'entities/Files/types';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Paper } from 'shared/ui/Paper';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { usePartnersStore } from 'widgets/Partners/state';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { useCustomModelStore } from 'widgets/customModel/state';
import { useFilesStore } from 'widgets/editFile/model/state';
import FormAutocomplete from 'shared/ui/autocomplete/form-autocomplete';

const PartnersSchema = yup.object({
	partnerId: yup
		.object({
			value: yup.string().notRequired(),
			label: yup.string().notRequired(),
		})
		.nullable(),
});

interface PartnersModalPropsType {
	open: boolean;
	handleClose: () => void;
	file?: FileType;
}

const PartnersModal: FC<PartnersModalPropsType> = ({ open, handleClose, file }) => {
	const { fileId } = useParams();
	const { t } = useTranslation();
	const [searchParam, setSearchParam] = useState<string | null>(null);
	const { partners, setPartnersData } = usePartnersStore();
	const { setFileDetails } = useFilesStore();
	const { setCustomFileDetails } = useCustomModelStore();

	const partnersMemo = useMemo(
		() =>
			partners?.results ? partners?.results.map((item) => ({ label: item.name, value: item._id })) : [],
		[partners],
	);

	const defaultValueForPartners = useMemo(
		() => partnersMemo?.find((item) => item.value === file?.partnerId),
		[partnersMemo, file],
	);

	useQuery({
		queryKey: ['get/partners', searchParam, file?.partnerId],
		queryFn: () =>
			getPartners({
				type: null,
				search: searchParam,
				partnerId: !open && file?.partnerId ? file?.partnerId : undefined,
			}),
		onSuccess: (response) => {
			setPartnersData(response.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	const { mutate, isLoading } = useMutation({
		mutationKey: ['patch/files/recognition-result'],
		mutationFn: retryImportFile,
	});

	const handleSubmit = (data: yup.InferType<typeof PartnersSchema>) => {
		mutate(
			{
				id: file?._id,
				data: {
					recognizedLabels: file?.recognizedLabels || [],
					assertionSetsId: file?.assertionSetsId || null,
					partnerId: data?.partnerId?.value || null,
					isPreview: true,
				},
			},
			{
				onSuccess: (response) => {
					enqueueSnackbar({
						message: t('partners.snackbar.success_settings_applying'),
						variant: 'success',
					});
					if (fileId) {
						setFileDetails(response.data);
					} else {
						setCustomFileDetails(response.data);
					}
					handleClose();
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{
				title: t('partners.title') as string,
			}}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '34px' }}>
				{t('partners.select_partner')}
			</Typography>

			<Formik
				initialValues={{
					partnerId: defaultValueForPartners || '',
				}}
				validationSchema={PartnersSchema}
				onSubmit={handleSubmit}
			>
				<Form>
					<FormAutocomplete
						name="partnerId"
						defaultValue={defaultValueForPartners}
						options={partnersMemo}
						label={t('partners.labels.client_supplier') as string}
						placeholder={t('partners.placeholder.client_supplier') as string}
						onSearch={(value) => setSearchParam(value)}
					/>
					<ActionsForDialog
						cancelLabel={t('shared_texts.cancel')}
						cancelAction={handleClose}
						applyLabel={t('shared_texts.continue')}
						applyActionDisabled={isLoading}
					/>
				</Form>
			</Formik>
		</Dialog>
	);
};

export default PartnersModal;
