import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { updatePersonalInfo } from 'entities/User/api';
import { User } from 'entities/User/types';
import { Form, Formik, FormikProps } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent, useMemo } from 'react';
import { useMutation } from 'react-query';
import { ColorPalette } from 'shared/consts/colorPalette';
import { compareObjects } from 'shared/helpers/compareObjects';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { InputField } from 'shared/ui/InputField';
import { FormContainer } from 'widgets/PersonalAccount/ui/Paper';
import { PersonalInfoValidationSchema } from 'widgets/PersonalAccount/ui/tabs/PersonalInfo/consts/validation';
import { PersonalInfoType } from 'widgets/PersonalAccount/ui/tabs/PersonalInfo/types';

export const PersonalInfo: FunctionComponent = () => {
	const { t } = useTranslation();
	const { user, updateAuthData } = useAuthStore();
	const { mutate } = useMutation({
		mutationFn: updatePersonalInfo,
	});

	const initialValues = useMemo(
		() => ({
			firstName: user?.firstName ?? '',
			lastName: user?.lastName ?? '',
		}),
		[user],
	);

	const handleSubmit = (values: PersonalInfoType): void => {
		const mutationValues = { ...values, language: user?.language };
		mutate(mutationValues, {
			onSuccess: () => {
				updateAuthData({
					user: { ...(user as User), firstName: values.firstName, lastName: values.lastName },
				});
				enqueueSnackbar({
					message: t('shared_texts.success_update'),
					variant: 'success',
				});
			},
			onError: (err) => {
				const defaultErrorMessage = t('shared_texts.failed_update');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	};

	return (
		<>
			<Typography variant={'h5'} color={ColorPalette.astronaut}>
				{t('personal_account.tab_1.title')}
			</Typography>
			<FormContainer sx={{ marginTop: '34px', marginBottom: '36px' }}>
				<Formik
					initialValues={initialValues}
					enableReinitialize
					validationSchema={PersonalInfoValidationSchema}
					onSubmit={handleSubmit}
				>
					{({ errors, values, resetForm }: FormikProps<PersonalInfoType>) => (
						<Form>
							<InputField
								name={'firstName'}
								label={t('auth.labels.name')}
								placeholder={t('auth.validation.enter_name')}
								required
							/>
							<InputField
								name={'lastName'}
								label={t('auth.labels.last_name')}
								placeholder={t('auth.validation.enter_last_name')}
								required
							/>
							<ActionsForDialog
								cancelLabel={t('shared_texts.cancel')}
								cancelAction={() => {
									resetForm();
								}}
								applyLabel={t('shared_texts.save')}
								applyActionDisabled={
									!isEmptyObject(errors) ||
									isEmptyObjectValues(values) ||
									isEmptyObject(compareObjects(initialValues, values))
								}
							/>
						</Form>
					)}
				</Formik>
			</FormContainer>
		</>
	);
};
