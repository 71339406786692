import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { FeaturesEnum } from 'entities/Company/types';
import { InputField } from 'shared/ui/InputField';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Paper } from 'shared/ui/Paper';
import { FormSwitch } from 'shared/ui/Switch/FormSwitch';

interface FeaturesType {
	feature: FeaturesEnum;
	isEnabled: boolean;
	neuralModelsLimit?: number;
}

interface FeaturesPropsTypes {
	features: FeaturesType[];
}

export const Features: FC<FeaturesPropsTypes> = ({ features }) => {
	const { t } = useTranslation();

	return (
		<Paper
			sx={{
				'paddingTop': '30px',
				'paddingBottom': '30px',
				'width': '100%',
				'display': 'flex',
				'flexDirection': 'column',
				'gap': '20px',
				'& > div:not(:last-child)': {
					paddingBottom: '20px',
					borderBottom: `1px solid ${ColorPalette.periwinkle}`,
				},
			}}
		>
			{features.map((item) => (
				<Box
					key={item.feature}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Box sx={{ display: 'flex', gap: '20px' }}>
						<FormSwitch name={item.feature} sx={{ marginRight: '10px' }} />
						<Typography variant={'h6'} color={ColorPalette.astronaut}>
							{t(`custom_model.model_types.${item.feature}`)}
						</Typography>
					</Box>

					{typeof item.neuralModelsLimit === 'number' && (
						<Box sx={{ 'width': '50%', '& > div': { margin: 0 } }}>
							<InputField
								name={'neuralModelsLimit'}
								type="number"
								label={''}
								placeholder={t('auth.validation.enter_name')}
							/>
						</Box>
					)}
				</Box>
			))}
		</Paper>
	);
};
