import { FC, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { getFile } from 'entities/Files/api';
import { getModelFile } from 'entities/CustomModel/api';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { useCustomModelStore } from 'widgets/customModel/state';
import { FilePreview } from 'widgets/editFile/FilePreview';
import { EFHeader } from 'widgets/editFile/Header';
import { useFilesStore } from 'widgets/editFile/model/state';

// "react-doc-viewer": "file:react-doc-viewer-0.1.5.tgz"
export const EditFile: FC = () => {
	const { fileId, modelId } = useParams();
	const { fileDetails, setFileDetails } = useFilesStore();
	const { currentCustomModelFiles, customFileDetails, setCustomFileDetails } = useCustomModelStore();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [totalFilePages, setTotalFilePages] = useState<number | null>(null);
	const [currentFilePage, setCurrentFilePage] = useState<number>(1);
	const handleChangeTotalFilePages = (n: number) => setTotalFilePages(n);
	const handleChangeCurrentFilePage = (n: number) => setCurrentFilePage(n);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [labelsWithPages, setLabelsWithPages] = useState<any>({});
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [currentLabels, setCurrentLabels] = useState<any>([]);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [allTableArray, setAllTableArray] = useState<any>([]);
	const [allLabels, setAllLabels] = useState<any>([]);
	const [stopRender, setStopRender] = useState<boolean>(true);

	const memoFileDetails = useMemo(() => customFileDetails || fileDetails, [fileDetails, customFileDetails]);

	useQuery({
		queryKey: ['get/fileById', fileId],
		queryFn: () => getFile(fileId),
		onSuccess: (response) => {
			setFileDetails(response?.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},

		enabled: !!fileId && !fileDetails,
	});

	useQuery({
		queryKey: ['get/modelById'],
		queryFn: () => getModelFile(modelId),
		onSuccess: (response) => {
			setCustomFileDetails(response?.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
		enabled: !!modelId && !customFileDetails,
	});

	const parceAllLabels = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const labelsPositions: any = [];
		if (memoFileDetails?.recognizedLabels) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const labelsArray: any = [];
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			memoFileDetails?.recognizedLabels?.length > 0 &&
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				memoFileDetails?.recognizedLabels?.forEach((item: any) => {
					if (item?.type === 'string') labelsArray.push(item);
				});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			labelsArray?.length > 0 &&
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				labelsArray?.forEach((item: any, i: number) => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					let itemObj: any = {};
					if (item?.boundingRegions?.length > 0) {
						itemObj = item?.boundingRegions[0]?.boxPosition;
						itemObj['data'] = {
							index: i,
						};
						itemObj['page'] = item?.boundingRegions[0]?.pageNumber;
						itemObj['name'] = item?.label || '';
						itemObj['text'] = item?.content || '';
						itemObj['id'] = item?._id || '';
						itemObj['assertionSetsId'] = item?.assertionsData?.assertionSetsId || null;
						itemObj['replaceVal'] = item?.assertionsData?.replaceVal || null;
						itemObj['autocomplete'] = item?.autocomplete || null;
						labelsPositions.push(itemObj);
					} else {
						itemObj = {
							x: -100,
							y: -100,
							width: 0,
							height: 0,
						};
						itemObj['data'] = {
							index: i,
						};
						itemObj['page'] = 1;
						itemObj['name'] = item?.label || '';
						itemObj['text'] = item?.content || '';
						itemObj['id'] = item?._id || '';
						itemObj['assertionSetsId'] = item?.assertionsData?.assertionSetsId || null;
						itemObj['replaceVal'] = item?.assertionsData?.replaceVal || null;
						itemObj['autocomplete'] = item?.autocomplete || null;
						labelsPositions.push(itemObj);
					}
				});
		}
		setAllLabels(labelsPositions);
		setStopRender(true);
	};

	useEffect(
		() => () => {
			setFileDetails(null);
			setCustomFileDetails(null);
		},
		[],
	);

	useEffect(() => {
		parceAllLabels();
	}, [memoFileDetails]);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const initialLabels: any = {};
		if (totalFilePages) {
			for (let i = 1; i <= totalFilePages; i++) {
				initialLabels[`${i}`] = [];
			}
			if (allLabels && allLabels.length) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				allLabels.forEach((item: any) => {
					initialLabels[`${item.page}`]?.push(item);
				});
			}
			if (stopRender) {
				setLabelsWithPages(initialLabels);
				setCurrentLabels(initialLabels[currentFilePage]);
			}
			setStopRender(false);
		}
	}, [totalFilePages, allLabels, stopRender]);

	useEffect(() => {
		if (labelsWithPages && labelsWithPages[currentFilePage]) {
			setCurrentLabels(labelsWithPages[currentFilePage]);
		}
	}, [labelsWithPages, currentFilePage]);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const tableArray: any = [];
		if (memoFileDetails?.recognizedLabels?.length) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			memoFileDetails?.recognizedLabels?.forEach((item: any) => {
				if (item?.type === 'table') {
					tableArray.push(item);
				}
			});
		}
		setAllTableArray(tableArray);
	}, [memoFileDetails?.recognizedLabels]);

	const tableLabelsArr = useMemo(() => {
		//eslint-disable-next-line @typescript-eslint/no-explicit-any
		let tableLabelsArr: any = [];
		if (allTableArray?.length > 0) {
			//eslint-disable-next-line @typescript-eslint/no-explicit-any
			tableLabelsArr = allTableArray.map((item: any) => {
				const columnsHeaders = [{ columnName: '' }];
				item?.columnsHeaders?.forEach((item: { columnName: string }) => {
					columnsHeaders.push(item);
				});
				const columnsCells = item?.cells;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const rowsIndexes = new Set(columnsCells?.map((item: any) => item?.rowIndex));
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const columnsObj: any = {};
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				rowsIndexes.forEach((item: any) => {
					columnsObj[item] = [];
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					columnsCells?.forEach((cell: any) => {
						if (cell.rowIndex === item) {
							columnsObj[item].push(cell);
						}
					});
				});
				const tableKeys = Object.keys(columnsObj);
				const tableValues = Object.values(columnsObj);
				return { columnsHeaders, tableKeys, tableValues };
			});

			return tableLabelsArr;
		}
	}, [allTableArray]);

	const submitChanges = () => {
		const allLabelsObjArray = Object.values(labelsWithPages).flat();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const submitArr: any[] = [];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		allLabelsObjArray.forEach((item: any) => {
			const isHasRegion =
				(item?.page && item?.x && item?.y && item?.width && item?.height) || item?.emptyText;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const submitObj: any = {
				label: item?.name?.toString(),
				content: item?.text?.toString(),
				assertionSetsId: item.assertionSetsId,
				replaceVal: item.replaceVal,
				type: 'string',
				...(isHasRegion
					? {
							boundingRegions: [
								{
									pageNumber: item?.page,
									boxPosition: {
										x: !item?.x || item?.x < 0 ? 0 : item.x,
										y: !item?.y || item?.y < 0 ? 0 : item.y,
										width: item?.width ?? 0,
										height: item?.height ?? 0,
									},
								},
							],
					  }
					: {}),
			};
			if (item?.id) {
				submitObj['_id'] = item?.id;
			}
			item?.name && submitArr.push(submitObj);
		});
		const submitObj = {
			labels: [...allTableArray, ...submitArr],
		};
		return submitObj;
	};

	const handleSetLabels = () => {
		setLabelsWithPages({ ...labelsWithPages, [currentFilePage]: currentLabels });
	};

	return (
		<>
			<EFHeader fileName={memoFileDetails?.fileName} />
			{memoFileDetails?.convertedFilePath && (
				<FilePreview
					file={memoFileDetails}
					modelId={modelId}
					currentCustomModelId={
						currentCustomModelFiles?.length > 0 && currentCustomModelFiles[0]?.customModelId
					}
					submitChanges={submitChanges}
					allLabels={allLabels}
					setAllLabels={setAllLabels}
					tableLabels={tableLabelsArr}
					tableLabelsData={allTableArray}
					setTableLabelsData={setAllTableArray}
					externallyChangedFile={memoFileDetails.recognizedLabels}
					labels={currentLabels}
					setLabels={setCurrentLabels}
					handleSetLabels={handleSetLabels}
					labelsWithPages={labelsWithPages}
					setLabelsWithPages={setLabelsWithPages}
					totalFilePages={totalFilePages}
					currentFilePage={currentFilePage}
					changeTotalFilePages={handleChangeTotalFilePages}
					changeCurrentFilePage={handleChangeCurrentFilePage}
				/>
			)}
		</>
	);
};
