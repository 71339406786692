import { Typography, Box, styled } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { postFileSettings } from 'entities/Files/api';
import { PageHeader } from 'shared/ui/PageHeader';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Button } from 'shared/ui/Button';
import { Paper } from 'shared/ui/Paper';
import { FormSelect } from 'shared/ui/Select/FormSelect';
import { FormSwitch } from 'shared/ui/Switch/FormSwitch';
import { DefaultExportDesination, DefaultExportType } from 'entities/Company/types';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { useAuthStore } from 'processes/auth/model/state';
import { Loader } from 'shared/ui/Loader';

const FileSettingsSchema = yup.object({
	defaultTypeEnabled: yup.bool(),
	defaultDestinationEnabled: yup.bool(),
	defaultType: yup.mixed().test('defaultType', 'shared_texts.field_is_required', function (value) {
		const { defaultTypeEnabled } = this.parent;
		return !(!value && defaultTypeEnabled);
	}),
	defaultDestination: yup
		.mixed()
		.test('defaultDestination', 'shared_texts.field_is_required', function (value) {
			const { defaultDestinationEnabled } = this.parent;
			return !(!value && defaultDestinationEnabled);
		}),
});

const FileSettings = () => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { company } = useAuthStore();

	const { mutate, isLoading } = useMutation({
		mutationKey: ['post/files/settings'],
		mutationFn: postFileSettings,
		onSuccess: async () => {
			await queryClient.refetchQueries({ queryKey: ['validate-token'] });
			enqueueSnackbar({ message: t('shared_texts.success_update'), variant: 'success' });
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	const handleSubmit = (data: yup.InferType<typeof FileSettingsSchema>) => {
		const { defaultTypeEnabled, defaultDestinationEnabled, defaultType, defaultDestination } = data;
		const payload = {
			defaultType: defaultTypeEnabled ? (defaultType as DefaultExportType) : undefined,
			defaultDestination: defaultDestinationEnabled
				? (defaultDestination as DefaultExportDesination)
				: undefined,
		};
		mutate({ ...payload });
	};

	if (isLoading) return <Loader />;

	return (
		<>
			<PageHeader title={t('files.settings.title_1')} description={t('files.settings.description_1')} />
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, marginBottom: 3 }}>
				<Typography variant={'h6'} color={ColorPalette.astronaut}>
					{t('files.settings.title_2')}
				</Typography>
				<Typography variant={'subtitle1'} color={ColorPalette.baliHai}>
					{t('files.settings.description_2')}
				</Typography>
			</Box>

			<Formik
				initialValues={{
					defaultType: company?.defaultExport?.defaultType || undefined,
					defaultDestination: company?.defaultExport?.defaultDestination || undefined,
					defaultTypeEnabled: !!company?.defaultExport?.defaultType || false,
					defaultDestinationEnabled: !!company?.defaultExport?.defaultDestination || false,
				}}
				validationSchema={FileSettingsSchema}
				onSubmit={handleSubmit}
			>
				{({ values, setFieldValue }) => (
					<Form>
						<Box
							sx={{
								'display': 'flex',
								'flexDirection': 'column',
								'alignItems': 'flex-end',
								'gap': 3,
								'@media screen and (min-width: 1441px)': {
									width: '50%',
								},
							}}
						>
							<Paper
								sx={{
									'paddingTop': '30px',
									'paddingBottom': '30px',
									'width': '100%',
									'display': 'flex',
									'flexDirection': 'column',
									'gap': '20px',
									'& > div:not(:last-child)': {
										paddingBottom: '20px',
										borderBottom: `1px solid ${ColorPalette.periwinkle}`,
									},
								}}
							>
								<SettingRowStyled>
									<SettingRowStyled>
										<FormSwitch name="defaultTypeEnabled" sx={{ marginRight: '10px' }} />
										<Typography variant={'h6'} color={ColorPalette.astronaut}>
											{t('files.settings.format')}
										</Typography>
									</SettingRowStyled>
									<FormSelect
										name={'defaultType'}
										placeholder="XLS, JSON, CSV, XML"
										options={defaultTypeOptions}
										value={values.defaultType || ''}
										onSelect={(value) => setFieldValue('defaultType', value)}
										required
										sx={{ width: '50%', alignSelf: 'flex-end', marginBottom: 0 }}
									/>
								</SettingRowStyled>

								<SettingRowStyled>
									<SettingRowStyled>
										<FormSwitch
											name="defaultDestinationEnabled"
											sx={{ marginRight: '10px' }}
										/>
										<Typography variant={'h6'} color={ColorPalette.astronaut}>
											{t('files.settings.destination')}
										</Typography>
									</SettingRowStyled>
									<FormSelect
										name={'defaultDestination'}
										placeholder="Computer, FTP"
										options={defaultExportDesinationOptions}
										value={values.defaultDestination || ''}
										onSelect={(value) => setFieldValue('defaultDestination', value)}
										required
										sx={{ width: '50%', marginBottom: 0 }}
									/>
								</SettingRowStyled>
							</Paper>
							<Button disabled={isLoading} size={'small'} type={'submit'}>
								{t('shared_texts.save')}
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default FileSettings;

const SettingRowStyled = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: '20px',
}));

const defaultTypeOptions = [
	{ value: DefaultExportType.XLSX, label: DefaultExportType.XLSX },
	{ value: DefaultExportType.XML, label: DefaultExportType.XML },
	{ value: DefaultExportType.JSON, label: DefaultExportType.JSON },
	{ value: DefaultExportType.CSV, label: DefaultExportType.CSV },
];

const defaultExportDesinationOptions = [
	{ value: DefaultExportDesination.DIRECT, label: 'Computer' },
	{ value: DefaultExportDesination.FTP, label: DefaultExportDesination.FTP },
];
