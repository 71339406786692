import { Box, FormControl, InputAdornment, InputBase, InputBaseProps, Menu } from '@mui/material';
import { addYears, endOfDay, format, startOfDay } from 'date-fns';
import { FunctionComponent, useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRange } from 'react-date-range';
import { Button } from '../Button';
import itLocale from 'date-fns/locale/it';
import enLocale from 'date-fns/locale/en-US';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Icon } from 'shared/ui/Icon';
import { InputError } from 'shared/ui/InputError';
import { InputLabel } from 'shared/ui/InputLabel'; // theme css file
import { useAuthStore } from 'processes/auth/model/state';

const locales = {
	it: itLocale,
	en: enLocale,
};

interface Props extends InputBaseProps {
	label: string;
	errorText?: string;
	startDate?: string;
	endDate?: string;
	changeRange: (startDate?: string, endDate?: string) => void;
}

export const DateRangeInput: FunctionComponent<Props> = ({
	sx,
	label,
	required,
	fullWidth = true,
	errorText,
	startDate,
	endDate,
	changeRange,
	...props
}) => {
	const { t } = useTranslation();
	const { user } = useAuthStore();

	const dateRangeLocale = useMemo(
		() => user?.language && locales[user?.language],
		[locales, user?.language],
	);

	const initialDatePickerState = {
		selection: {
			startDate: startDate ? new Date(startDate) : new Date(),
			endDate: endDate ? new Date(endDate) : new Date(),
			key: 'selection',
			autoFocus: true,
		},
	};
	const [datePickerState, setDatePickerState] = useState(initialDatePickerState);
	const handleChangeRange = useCallback(
		(discard?: boolean) => {
			if (discard) {
				return changeRange(undefined, undefined);
			}
			changeRange(
				startOfDay(datePickerState.selection.startDate).toISOString(),
				endOfDay(datePickerState.selection.endDate).toISOString(),
			);
		},
		[datePickerState.selection],
	);

	const inputValue = () => {
		if (!startDate || !endDate) return '';
		return t('shared_texts.input_value.date', {
			from: format(new Date(startDate), 'dd/MM/yyyy'),
			to: format(new Date(endDate), 'dd/MM/yyyy'),
		});
	};

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	return (
		<>
			<FormControl
				variant={'standard'}
				fullWidth={fullWidth}
				sx={{ 'marginBottom': '40px', '&, & *': { cursor: 'pointer' }, ...sx }}
				onClick={handleClick}
				aria-autocomplete={'none'}
			>
				<InputLabel name={label} label={label} required={required} />

				<InputBase
					id={label}
					type={'text'}
					value={inputValue()}
					autoComplete="off"
					endAdornment={
						<InputAdornment position="end">
							<Icon
								id={'date_today_light'}
								style={{
									color: ColorPalette.royalBlue,
								}}
							/>
						</InputAdornment>
					}
					sx={{
						'& .MuiInputBase-input': {
							'height': 'initial',
							'margin': '0',

							'padding': '20px 16px',
							'border': `1px solid ${
								errorText ? ColorPalette.violetRed : ColorPalette.periwinkle
							}`,
							'borderRadius': '10px',

							'fontWeight': 600,
							'fontSize': '14px',
							'lineHeight': '18px',

							'color': ColorPalette.astronaut,
							'&:placeholder': {
								color: ColorPalette.baliHai,
							},
						},
						'&.MuiInputBase-root': {
							'position': 'relative',
							'& .MuiInputAdornment-root': {
								position: 'absolute',
								transform: 'translateX(-50%)',
								right: '9px',
							},

							'&.Mui-focused .MuiInputBase-input': {
								border: `1px solid ${
									errorText ? ColorPalette.violetRed : ColorPalette.periwinkle
								}`,
							},
							'& .Mui-disabled': {
								border: `1px solid ${ColorPalette.zircon}`,
							},
						},

						'label + &': {
							marginTop: '18px',
						},
					}}
					{...props}
				/>

				<InputError text={errorText} />
			</FormControl>
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				autoFocus={false}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				sx={{
					'.MuiList-root': {
						padding: '0',
					},
				}}
			>
				<Box
					sx={{ width: 'fit-content', display: 'flex', flexDirection: 'column', paddingBottom: 2 }}
				>
					<DateRange
						locale={dateRangeLocale}
						onChange={(item) => setDatePickerState({ ...datePickerState, ...item })}
						minDate={addYears(new Date(), -5)}
						maxDate={addYears(new Date(), 5)}
						direction="vertical"
						ranges={[datePickerState.selection]}
						preventSnapRefocus={true}
					/>
					<Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
						<Button
							variant={'outlined'}
							size={'small'}
							onClick={() => {
								setDatePickerState(initialDatePickerState);
								handleChangeRange(true);
								handleClose();
							}}
						>
							{t('shared_texts.discard')}
						</Button>
						<Button
							variant={'contained'}
							size={'small'}
							type={'submit'}
							onClick={() => {
								handleChangeRange();
								handleClose();
							}}
						>
							{t('shared_texts.apply')}
						</Button>
					</Box>
				</Box>
			</Menu>
		</>
	);
};
